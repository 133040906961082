import { Switch, TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

export const SwitchFieldRa = (props: {
  name: string
  control: any
  label: string
  errors: any
  placeholder?: string
  autoFocus?: boolean
  onKeyDown?: (e: any) => void
  disabled?: boolean
  focused?: boolean
  id?: string
  type?: string
  autoComplete?: string
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <div className={'mui-input'}>
          <label className={'mui-input__label small-text'}>{props.label}</label>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={'small-text'}>В процентах</div>
            <div>
              <Switch
                {...props}
                {...field}
                checked={field.value}
                className={'mui-input__field'}
                placeholder={props.placeholder || 'Введите текст'}
              />
            </div>
            <div className={'small-text'}>В рублях</div>
          </div>
        </div>
      )}
    />
  )
}
