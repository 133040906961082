import React from 'react'
import { useNavigate } from 'react-router'
import './style.scss'

export const GoBack = ({ to }: { to?: string }) => {
  const navigate = useNavigate()

  const url = to ?? -1

  return (
    <div onClick={() => navigate(url as any)} className={'back'}>
      <img src={'/assets/back.svg'} />
      Назад
    </div>
  )
}
