import { Button, FormControl } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { TextInputRa } from '../../components/inputs/text-field'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { phoneClear } from '../../formatters/clear-phone'

type Props = {
  setPage: (value: number) => void
  getDefaultFilter: () => void
  navigate: (url: string) => void
  location: any
}

export const Filter = (props: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyInn: '',
      title: '',
      subjectForm: null,
      cardNumber: '',
    },
  })

  const resetFilter = () => {
    reset({
      title: '',
      subjectForm: null,
      companyInn: '',
      cardNumber: '',
    })
    props.setPage(0)
    props.getDefaultFilter()
  }
  const onSubmit = (values: any) => {
    let params = new URLSearchParams(props.location.search)
    for (let i = 0; i < Object.keys(values).length; i++) {
      Object.entries(values).map((value, key) => {
        if (value[1] !== null && String(value[1]).length) {
          if (value[0] === 'phone') {
            if (String(phoneClear((value[1] as string) || ''))) {
              params.set(value[0], phoneClear(value[1] as any) as string)
            }
          } else {
            params.set(value[0], value[1] as string)
          }
        }
      })
    }
    params.set('page', '1')
    props.setPage(0)
    props.navigate(`/clients?${params.toString()}`)
  }
  return (
    <div className={'na-filter'} style={{ marginBottom: 30 }}>
      <FormControl fullWidth className={'mui-input'}>
        <div className={'na-filter__fields'}>
          <div>
            <TextInputRa
              name={'title'}
              label={'ФИО или Компания'}
              control={control}
              errors={errors}
              placeholder={'Введите фио или компанию'}
            />
          </div>
          <div>
            <TextInputPhoneRa
              name={'phone'}
              control={control}
              label={'Телефон'}
              errors={errors}
            />
          </div>
          <div>
            <TextInputRa
              name={'companyInn'}
              label={'ИНН компании'}
              control={control}
              errors={errors}
              placeholder={'Введите инн компании'}
            />
          </div>
          <div>
            <TextInputRa
              name={'cardNumber'}
              label={'Номер карты'}
              control={control}
              errors={errors}
              placeholder={'Введите номер карты клиента'}
            />
          </div>
          <div>
            <TextInputSelectRa
              options={[
                { name: 'Юридическое лицо ', id: 2 },
                { name: 'Физическое лицо', id: 1 },
              ]}
              name={'subjectForm'}
              label={'Тип клиента'}
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={() => {
              resetFilter()
              reset()
            }}
          >
            Очистить
          </Button>
        </div>
      </FormControl>
    </div>
  )
}
