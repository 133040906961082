export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 1px 16px rgb(0 46 82 / 5%)',
  pt: 2,
  px: 4,
  pb: 3,
}
