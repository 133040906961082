import { format } from 'date-fns'
import printJS from 'print-js'
import React from 'react'

export function getPrintReport({
  print,
  filter,
}: {
  print: string
  filter: any
}) {
  return printJS({
    printable: `<div>
    <div style="display:flex; flex-direction:column;">
        <div>Дата от: ${
          filter.dateFrom
            ? format(new Date(filter.dateFrom), 'dd.MM.yyyy в HH:mm')
            : '-'
        }</div>
        <div>Дата до: ${
          filter.dateTo
            ? format(new Date(filter.dateTo), 'dd.MM.yyyy в HH:mm')
            : '-'
        }</div>
    </div>
    <div>${print}</div>
          </div>`,
    type: 'raw-html',
  })
}
