import { Button } from '@mui/material'
import { format } from 'date-fns'
import React from 'react'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { CardComponent } from '../../components/card'
import { TitleComponent } from '../../components/title'
import { getStatus, StatusCard } from '../../hooks/useStatus'
import './style.scss'
import { Balance } from '../../types/balance'
import { CardDetailBalanceWithdraw, permission } from '../../permission'
import FormWriteOff from './form-write-off-fuel'

function prettify(num: String) {
  var n = num.toString()
  return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' - ')
}

function getBalances(balances: Balance[]) {
  if (!balances) {
    return '-'
  } else {
    return balances
      .filter(item => Number(item.literAmount) !== 0)
      .map(
        item =>
          `${item.fuel.title} = ${
            Math.trunc(Number(item.literAmount) * 100) / 100
          } л. <br/>`
      )
      .join('')
  }
}

const ListElement = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className={'active-card__list__elem'}>
      <div className={'active-card__list__elem__title medium-text'}>
        {title}:
      </div>
      <div
        className={'active-card__list__elem__value medium-text'}
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </div>
  )
}

type LocationState = {
  id: number
  activatedAt: string
  balances: Balance[]
  customerID: number
  kind: number
  number: string
  status: StatusCard
  uuid: string
}

const ActiveCardPage = () => {
  const currentLocation: { state: LocationState } = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    if (!currentLocation?.state?.id) {
      return navigate('/')
    }
  })
  return (
    <React.Fragment>
      <FormWriteOff
        handleClose={handleClose}
        open={open}
        defaultValues={{
          uuid: currentLocation.state.uuid,
          number: currentLocation.state?.number,
        }}
        balances={currentLocation.state.balances || []}
      />
      <div style={{ paddingBottom: 40 }}>
        <div className={'container active-card'}>
          <TitleComponent title={'Активная карта клиента'} />
          <div className={'active-card__info'} style={{ alignItems: 'center' }}>
            <div className={'active-card__info__card'}>
              <CardComponent {...currentLocation.state} />
            </div>
            <div className={'active-card__info__data'}>
              <ListElement
                title={'Номер карты'}
                value={prettify(currentLocation.state?.number) || '-'}
              />
              <ListElement
                title={'Статус'}
                value={getStatus(currentLocation.state?.status) || '-'}
              />
              <ListElement
                title={'Баланс'}
                value={
                  (getBalances(currentLocation.state.balances) as any) || '-'
                }
              />
              <ListElement
                title={'Дата активации'}
                value={format(
                  new Date(currentLocation.state.activatedAt),
                  'dd.MM.yyyy в HH:mm'
                )}
              />
            </div>
            <div className={'active-card__info__buttons'}>
              {permission(CardDetailBalanceWithdraw) &&
                currentLocation.state?.status === 'active' && (
                  <Button
                    variant="contained"
                    className={'btn-default btn-blue'}
                    onClick={handleOpen}
                  >
                    Списать баланс
                  </Button>
                )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ActiveCardPage
