import axios from 'axios'
import env from '../env'
import { Client } from '../types/clients'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const listFuels = () => {
  return baseURL().get(`fuels`)
}
export const addedFuels = (props: Client) => {
  return baseURL().post(`fuels`, { ...props })
}

export const updateFuels = (props: Client) => {
  return baseURL().put(`fuels/${props.id}`, { ...props })
}

export const deleteFuels = (props: { id: number }) => {
  return baseURL().delete(`fuels/${props.id}`)
}
export const oneFuels = (props: { id: number }) => {
  return baseURL().get(`fuels/${props.id}`)
}
export const reportFuel = (props: { workingShiftID: number }) => {
  return baseURL().get(
    `reports/fuel-consumptions?workingShiftID=${props.workingShiftID}`
  )
}

export const reportsFuels = (props: any) => {
  let params = new URLSearchParams('')
  Object.entries(props).map((value, key) => {
    if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
      //@ts-ignore
      params.set(value[0], new Date(value[1]).toJSON())
    } else if (value[1]) {
      params.set(value[0], value[1] as string)
    }
  })
  return baseURL().get(`reports/fuel-consumptions?${params.toString()}`)
}
