import React from 'react'
import { formatMoney } from '../../../../formatters/formatMoney'
import { getLiter } from '../../../../helpers/getLiter'
import { Balance } from '../../../../types/balance'
import './style.scss'

type Props = {
  balances?: Balance[]
}

export const TableListBalances = (props: Props) => {
  const { balances } = props

  return (
    <div className={'table-list-balances-show-client'}>
      <div className={'table-list-balances-show-client__balance-header'}>
        <div className="small-text" style={{ width: 150 }}>
          Вид топлива
        </div>
        <div className="small-text" style={{ width: 50 }}>
          Цена
        </div>
        <div className="small-text" style={{ width: 90 }}>
          Сумма
        </div>
        <div className="small-text" style={{ width: 68 }}>
          Литры
        </div>
      </div>
      {balances?.map(balance => (
        <div
          className={'table-list-balances-show-client__balance-body'}
          key={balance.id}
        >
          <div className={'small-text'} style={{ width: 150 }}>
            {balance.fuel.title}
          </div>
          <div className={'small-text'} style={{ width: 50 }}>
            {balance.purchasePrice}
          </div>
          <div className={'small-text'} style={{ width: 90 }}>
            {formatMoney(balance.amount)} ₽
          </div>
          <div className={'small-text'} style={{ width: 68 }}>
            {getLiter(balance.amount, balance.purchasePrice)}
          </div>
        </div>
      ))}
    </div>
  )
}
