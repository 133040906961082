import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getParamsRequest } from '../helpers/getParamsRequest'
import { searchFilterRequest } from '../request/transaction'

type Props = {
  url: string
  resource: string
  defaultFilter?: any
}

export const useFilters = (props: Props) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = React.useState<any>({})

  React.useEffect(() => {
    getDefaultFilter(location.search)
  }, [page, rowsPerPage])

  React.useEffect(() => {
    if (location?.search) {
      request(location?.search)
    }
  }, [location.search])

  React.useEffect(() => {
    getDefaultFilter(props.defaultFilter || {})
  }, [])

  function request(filter: string) {
    return searchFilterRequest({
      filter: filter,
      resource: props.resource,
    }).then(result => setData(result.data))
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  function getDefaultFilter(value?: string) {
    let params = new URLSearchParams(value || '')
    params.set('countPerPage', String(rowsPerPage))
    params.set('page', String(page + 1))
    navigate(`/${props.url}?${params.toString()}`)
  }
  return {
    getDefaultFilter,
    handleChangePage,
    setPage,
    data,
    location,
    setRowsPerPage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    navigate,
    request,
  }
}
