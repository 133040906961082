import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router'
import { ModalDelete } from '../../components/modal-delete'
import FormModal from './form'
import { FuelDelete, FuelUpdate, permission } from '../../permission'
import { Fuel } from '../../types/fuel'
import { deleteFuels } from '../../request/fuels'

interface Column {
  id: 'id' | 'title'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

interface Data {
  id: number
  title: string
}

const ListFuels = (props: {
  handleClose: () => void
  open: boolean
  handleOpen: () => void
  list: Fuel[]
  getList: () => void
}) => {
  const navigate = useNavigate()

  const [isDelete, setIsDelete] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState<Number | null>(null)
  const [openModal, setOpenModal] = React.useState(false)

  const handleOpen = () => setIsDelete(true)

  const checkIdDelete = (id: number) => {
    setIdDelete(id)
    handleOpen()
  }
  const handleDelete = () =>
    deleteFuels({ id: idDelete as any }).then(() => {
      handleClose()
      setIdDelete(null)
    })

  React.useEffect(() => {
    if (!props.open) {
      setIdDelete(null)
    }
  }, [props.open])

  const handleClose = () => {
    setIsDelete(false)
    return props.getList()
  }

  const columns: readonly Column[] = [
    { id: 'title', label: 'Наименование', minWidth: 150 },
    {
      id: 'id',
      label: '',
      minWidth: 120,
      align: 'right',
      //@ts-ignore
      format: value => (
        <div className={'icons-delete-edit'}>
          {permission(FuelUpdate) && (
            <img
              onClick={() => {
                props.handleOpen()
                setIdDelete(value)
              }}
              src={'/assets/icons/edit.svg'}
            />
          )}
          {/* {permission(FuelDelete) && (
            <img
              onClick={() => checkIdDelete(value)}
              src={'/assets/icons/delete.svg'}
            />
          )} */}
        </div>
      ),
    },
  ]

  return (
    <>
      <FormModal
        //@ts-ignore
        key={idDelete}
        handleClose={props.handleClose}
        open={props.open}
        data={
          {
            ...(props.list.filter(
              item => Number(item.id) === Number(idDelete)
            )?.[0] as any),
            roleIds: props.list.filter(
              item => Number(item.id) === Number(idDelete)
              //@ts-ignore
            )?.[0]?.roles?.[0]?.id,
          } || (null as any)
        }
      />
      <ModalDelete
        handleClose={handleClose}
        handleDelete={handleDelete}
        open={isDelete}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value as any) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default ListFuels
