import * as yup from 'yup'

export const schemaFuel = yup
  .object({
    id: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    literAmount: yup
      .number()
      .transform((_, value) => {
        if (value.includes('.')) {
          return +value
        }
        return +value.replace(/,/, '.')
      })
      .max(99999, 'Максимальное кол-во литров для разового списания 99999')
      .min(0, 'Отрицательного значения не может быть')
      .required('Обязательно к заполнению')
      .typeError('Сумма должна быть в литрах'),
  })
  .required()

export const schemaRefunds = yup
  .object({
    literAmount: yup
      .number()
      .transform((_, value) => {
        if (value.includes('.')) {
          return +value
        }
        return +value.replace(/,/, '.')
      })
      .max(99999, 'Сумма не должна привышать размер списания')
      .min(0, 'Отрицательного значения не может быть больше')
      .required('Обязательно к заполнению')
      .typeError('Сумма в литрах'),
  })
  .required()
