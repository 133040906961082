import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from '@mui/material'

import {
  CustomerDelete,
  CustomerUpdate,
  permission,
} from '../../../../permission'
import { deleteClients } from '../../../../request/clients'

type Props = {
  clientId: number
  setOpenModalChangePassword: (status: boolean) => void
}

export const ToolbarButtonClientInfo = (props: Props) => {
  const { clientId, setOpenModalChangePassword } = props

  const navigate = useNavigate()

  return (
    <div style={{ width: 200, marginLeft: 'auto' }}>
      {permission(CustomerUpdate) && (
        <Button
          variant="contained"
          className={'btn-default btn-blue bnt-icon-ok'}
          onClick={() => {
            navigate(`/clients/edit/${clientId}`)
          }}
        >
          Редактировать
        </Button>
      )}
      {permission(CustomerUpdate) && (
        <Button
          variant="contained"
          style={{ marginTop: 20 }}
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={() => setOpenModalChangePassword(true)}
        >
          Сменить пароль
        </Button>
      )}
      {permission(CustomerDelete) && (
        <Button
          variant="contained"
          style={{ marginTop: 20 }}
          className={'btn-default btn-red bnt-icon-ok'}
          onClick={() => {
            deleteClients({ id: clientId }).then(() => {
              navigate(`/clients`)
            })
          }}
        >
          Удалить клиента
        </Button>
      )}
    </div>
  )
}
