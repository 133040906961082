import { Button } from '@mui/material'
import React from 'react'
import { getStatus } from '../../../../hooks/useStatus'
import { Card } from '../../../../types/card'
import { TableListBalances } from '../balance-card/TableListBalances'

type Props = {
  cards: Card[] | []
  handleOpenCorrect: ({ uuid }: { uuid: string }) => void
  handleOpenFuel: (uuid: string) => void
}

const ComponentTitle = ({ text }: { text: string }) => (
  <div className={'small-text '} style={{ color: 'var(--color-text-opacity)' }}>
    {text}
  </div>
)

export const TableListCards = (props: Props) => {
  const { cards, handleOpenCorrect, handleOpenFuel } = props

  return (
    <div className={'client-form__list-cards__table'}>
      <div className={'client-form__list-cards__table__header'} />
      {cards.map((item: Card) => {
        return (
          <div
            key={item.id}
            className={'client-form__list-cards__table__content'}
          >
            <div
              className={'medium-text'}
              style={{ fontWeight: 600, width: 150 }}
            >
              <ComponentTitle text={'Номер карты'} />
              {item.number}
              <ComponentTitle text={'Статус карты'} />
              <div
                style={{
                  color: item.status === 'active' ? '#0A5F38' : '#d22323',
                  fontWeight: 600,
                }}
              >
                {getStatus(item.status as any)}
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <TableListBalances balances={item.balances} />
            </div>

            <div style={{ marginLeft: 'auto' }} className={'icons-delete-edit'}>
              <Button
                variant="contained"
                className={'btn-default btn-green bnt-icon-ok'}
                onClick={() => handleOpenFuel(item.uuid)}
              >
                Пополнить баланс
              </Button>
              <Button
                style={{ marginTop: 16 }}
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={() => handleOpenCorrect({ uuid: item.uuid })}
              >
                Коррекция баланса
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
