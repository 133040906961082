import axios from 'axios'
import React, { useCallback } from 'react'
import env from '../env'

type Props = { url: string; method?: string; params?: any }

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const useRequest = ({ url, method = 'GET', params }: Props) => {
  const [data, setData] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [errors, setError] = React.useState(null)

  React.useEffect(() => {
    search()
  }, [])

  const clearData = useCallback(() => {
    setData(null)
  }, [])

  const search = (newParams?: any) => {
    let urlSearch = url
    if (newParams) {
      urlSearch = newParams
    }
    setLoader(true)
    setError(null)
    baseURL()
      .get(urlSearch)
      .then(res => res.data)
      .then(
        result => {
          setData(result)
          setLoader(false)
        },
        error => {
          setError(error)
          setLoader(false)
        }
      )
  }

  return { data, search, loader, clearData, errors }
}
