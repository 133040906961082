import React from 'react'
import { useNavigate } from 'react-router'
import { formatMoney } from '../../../formatters/formatMoney'
import './style.scss'

const ListFuels = ({ fuels }: { fuels: any }) => {
  return fuels.map((fuel: any, key: number) => (
    <div className={'card-fuel'} key={key}>
      <div className={'medium-text'}>{fuel.fuel}</div>
      <div className={'medium-text'}>
        {formatMoney(Math.abs(fuel.amountSum))} ₽
      </div>
      <div className={'medium-text'}>{Math.abs(fuel.literAmountSum)} л.</div>
    </div>
  ))
}

type Props = {
  fuels: any
  title: string
  customerId: number
}

export const Row = (props: Props) => {
  const { fuels, title, customerId } = props

  const navigate = useNavigate()

  return (
    <div
      className={'report-components-row'}
      onClick={() =>
        navigate(`/clients/${customerId}`, {
          state: { step: 2 },
        })
      }
    >
      <div
        className={'report-components-row__title medium-text'}
        style={{ fontWeight: 500 }}
      >
        {title}
      </div>
      <div className={'report-components-row__body'}>
        <div className={'report-components-row__body__header'}>
          <div className={'small-text'}>Наименование</div>
          <div className={'small-text'}>Сумма ₽</div>
          <div className={'small-text'}>Кол-во л.</div>
        </div>
        <ListFuels fuels={fuels} />
      </div>
    </div>
  )
}
