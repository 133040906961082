import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const schema = yup
  .object({
    lastName: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    firstName: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    phone: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()

export const schemaCard = yup
  .object({
    customerID: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    number: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()

export const schemaCorrect = yup
  .object({
    id: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    amount: yup
      .number()
      .max(999999, 'Максимально 999 999 ₽')
      .min(0, 'Минимально 0 ₽')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })

  .required()

export const schemaFuel = yup
  .object({
    fuelID: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    amount: yup
      .number()
      .max(999999, 'Максимально 999999₽')
      .min(1, 'Минимально 1₽')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    count: yup
      .number()
      .max(99999, 'Максимально 99999 литр')
      .min(1, 'Минимально 1 литр')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()

export const schemaChangePassword = yup
  .object({
    password: yup
      .string()
      .min(6, 'Минимальная длинна 6 символов')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
export const schemaChangePinCode = yup
  .object({
    pinCode: yup
      .string()
      .length(4, 'Длинна пинкода должна быть 4 символа')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
