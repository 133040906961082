import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { updatePinCode } from '../../request/cards'
import { style } from '../../styles/style'
import { schemaChangePinCode } from './schema'

type Props = {
  handleClose: () => void
  open: boolean
  uuid: number
}

export const ModalChangePinCode = (props: Props) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaChangePinCode),
    defaultValues: { pinCode: null },
  })

  const [success, setSuccess] = React.useState(false)

  const onSubmit = (values: any) => {
    updatePinCode({ number: props.uuid, ...values })
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          props.handleClose()
          setSuccess(false)
          reset()
        }, 1000)
      })
      .catch(error =>
        setError('pinCode', { message: error.response?.data?.description })
      )
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Смена пинкода
        </h2>
        {success ? (
          <div className={'medium-text'}>Пинкод изменен успешно!</div>
        ) : (
          <FormControl fullWidth className={'mui-input'}>
            <TextInputRa
              name={'pinCode'}
              label={'Новый пинкод'}
              control={control}
              errors={errors}
            />
            <div className={'station-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Изменить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={props.handleClose}
              >
                Отмена
              </Button>
            </div>
          </FormControl>
        )}
      </Box>
    </Modal>
  )
}
