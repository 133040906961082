import React from 'react'
import './App.scss'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { SignIn } from './pages/auth'
import { AuthContext } from './context/authProvider'
import Header from './pages/header'
import ActiveCardPage from './pages/active-card'
import StationPage from './pages/stations'
import CreateStation from './pages/stations/create'
import EditStation from './pages/stations/edit'
import UsersPage from './pages/users'
import ClientPage from './pages/clients'
import CreateClient from './pages/clients/create'
import EditClient from './pages/clients/edit'
import { userMe } from './request'
import {
  CardTransactionHistoryReadList,
  CustomerCreate,
  CustomerRead,
  CustomerUpdate,
  EmployeeRead,
  FuelRead,
  permission,
  PetrolStationCreate,
  PetrolStationRead,
  PetrolStationUpdate,
  ReportFuelConsumptionRead,
} from './permission'
import FuelsPage from './pages/fuels'
import HomePage from './pages/home'
import TransactionsPage from './pages/transactions'
import ShiftsPage from './pages/shifts'
import ShowStation from './pages/stations/show'
import ReportsPage from './pages/reports'
import ShowClient from './pages/clients/show'
import { WorkingShiftContext } from './context/workingShiftProvider'
import { getActiveWorkingShiftID } from './helpers/getActiveWorkingShiftID'

const App = () => {
  const [isAuth, setIsAuth] = React.useState<Boolean>(false)
  const [isOpenWorkingShift, setIsOpenWorkingShift] =
    React.useState<Boolean>(false)

  React.useEffect(() => {
    userMe().then(() => {
      setIsAuth(true)
      setIsOpenWorkingShift(getActiveWorkingShiftID() ? true : false)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      <WorkingShiftContext.Provider
        value={{ isOpenWorkingShift, setIsOpenWorkingShift }}
      >
        <BrowserRouter>
          {isAuth ? (
            <>
              {isAuth && <Header />}
              <Routes>
                <Route path={'/active-card'} element={<ActiveCardPage />} />
                {ReportFuelConsumptionRead && (
                  <Route path={'/reports'} element={<ReportsPage />} />
                )}
                {permission(PetrolStationRead) && (
                  <Route path={'/stations'} element={<StationPage />} />
                )}
                {permission(PetrolStationCreate) && (
                  <Route
                    path={'/stations/create'}
                    element={<CreateStation />}
                  />
                )}
                {permission(PetrolStationRead) && (
                  <Route path={'/stations/:id'} element={<ShowStation />} />
                )}
                {permission(PetrolStationUpdate) && (
                  <Route
                    path={'/stations/edit/:station'}
                    element={<EditStation />}
                  />
                )}
                {permission(EmployeeRead) && (
                  <Route path={'/users'} element={<UsersPage />} />
                )}
                {permission(FuelRead) && (
                  <Route path={'/fuels'} element={<FuelsPage />} />
                )}
                {permission(CustomerRead) && (
                  <Route path={'/clients'} element={<ClientPage />} />
                )}
                {permission(CustomerRead) && (
                  <Route path={'/clients/:id'} element={<ShowClient />} />
                )}
                {permission(CustomerCreate) && (
                  <Route path={'/clients/create'} element={<CreateClient />} />
                )}

                {permission(CardTransactionHistoryReadList) && (
                  <Route
                    path={'/transactions'}
                    element={<TransactionsPage />}
                  />
                )}
                {permission(ReportFuelConsumptionRead) && (
                  <Route path={'/report-shifts'} element={<ShiftsPage />} />
                )}
                {permission(CustomerUpdate) && (
                  <Route
                    path={'/clients/edit/:client'}
                    element={<EditClient />}
                  />
                )}
                <Route path="*" element={<HomePage />} />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route path="*" element={<SignIn />} />
            </Routes>
          )}
        </BrowserRouter>
      </WorkingShiftContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
