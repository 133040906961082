import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TitleComponent } from '../../components/title'
import { EmployeeCreate, permission } from '../../permission'
import { listEmployee } from '../../request/employee'
import ListStations from './list'
import './style.scss'

const UsersPage = () => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => setOpen(true)

  return (
    <div className={'stations container'}>
      <div className="stations__toolbar">
        <div>
          <TitleComponent title={'Список сотрудников'} />
        </div>

        <div>
          {permission(EmployeeCreate) && (
            <Button className={' btn-default btn-gray'} onClick={handleOpen}>
              Добавить сотрудника
            </Button>
          )}
        </div>
      </div>
      <ListStations
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  )
}

export default UsersPage
