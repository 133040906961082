import { format } from 'date-fns'
import React from 'react'

type Props = {
  date: string | Date
  type: string
}

export function getFormatDate(props: Props) {
  const { date, type = 'dd.MM.yyyy' } = props
  if (!date) {
    return '-'
  }
  return format(new Date(date), type)
}
