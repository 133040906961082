import { format } from 'date-fns'
import printJS from 'print-js'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserName } from '../../helpers/getUserName'
import { addedClients } from '../../request/clients'
import Form from './form'

const CreateClient = () => {
  const navigate = useNavigate()
  return (
    <Form
      title={'Добавить клиента'}
      onSubmit={(values: any, setErrors: any) => {
        addedClients(values)
          .then(data => {
            printJS({
              printable: `<div id="print-content" className={'print-content'} >
                  <div style="display:flex"><div><img src="/assets/logo.png" style=" width: 200px" /></div><div style='margin:auto'>  ${format(
                    new Date(),
                    'dd.MM.yyyy в HH:mm'
                  )}</div></div>
                  <hr></hr>
                  <div>ФИО: ${getUserName(values)}</div>
                  <div>Логин: ${data.data.phone}</div>
                  <div>Пароль: ${data.data.password}</div>
                </div>`,
              type: 'raw-html',
            })
            navigate(`/clients/${data.data.id}`, {
              state: { id: data.data.id },
            })
          })
          .catch(error => setErrors(error?.response?.data?.description))
      }}
    />
  )
}
export default CreateClient
