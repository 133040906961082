import { Button, FormControl } from '@mui/material'
import { endOfMonth, startOfMonth } from 'date-fns'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { getAdminRole } from '../../helpers/getAdminRole'
import { getParamsRequest } from '../../helpers/getParamsRequest'
import { useRequest } from '../../request/use-request'

type Props = {
  setPage: (value: number) => void
  getDefaultFilter: () => void
  navigate: (url: string) => void
  location: any
  defaultFilter: any
}

export const Filter = (props: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      petrolStationID: '',
      ...props.defaultFilter,
    },
  })

  const resetFilter = () => {
    reset({
      petrolStationID: '',
      dateTo: '',
      dateFrom: '',
    })
    props.setPage(0)
    props.getDefaultFilter()
  }
  const { data: petrolStation }: { data: any } = useRequest({
    url: '/petrol-stations',
  })
  const onSubmit = (values: any) => {
    const params = getParamsRequest({
      location: props.location,
      setPage: props.setPage,
      values,
    })
    props.navigate(`/report-shifts?${params.toString()}`)
  }
  return (
    <div className={'na-filter'} style={{ marginBottom: 30 }}>
      <FormControl fullWidth className={'mui-input'}>
        <div className={'na-filter__fields'}>
          {getAdminRole() && (
            <div>
              <TextInputSelectRa
                name={'petrolStationID'}
                control={control}
                label={'Станция АЗС'}
                errors={errors}
                options={
                  petrolStation?.items?.map((item: any) => ({
                    id: item.id,
                    name: item.title,
                  })) || []
                }
              />
            </div>
          )}
          <div>
            <TextInputDateRa
              name={'dateFrom'}
              control={control}
              label={'Дата от'}
              errors={errors}
            />
          </div>
          <div>
            <TextInputDateRa
              name={'dateTo'}
              control={control}
              label={'Дата до'}
              errors={errors}
            />
          </div>
        </div>
        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={() => {
              resetFilter()
              reset()
            }}
          >
            Очистить
          </Button>
        </div>
      </FormControl>
    </div>
  )
}
