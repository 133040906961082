import { petrolStations } from '../request/index'
export function getPetrolStation(
  type: 'title' | 'address' | 'all' | 'phone' | 'id'
) {
  const petrolStation = JSON.parse(
    localStorage.getItem('user') as any
  )?.petrolStation
  if (petrolStation) {
    switch (type) {
      case 'address':
        return petrolStation.address
      case 'title':
        return petrolStation.title
      case 'all':
        return petrolStation.title + ' ' + petrolStation.address
      case 'id':
        return petrolStation.id
    }
  } else return '-'
}
