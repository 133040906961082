import * as React from 'react'
import { Button, FormControl } from '@mui/material'
import { useRequest } from '../../request/use-request'
import { useForm } from 'react-hook-form'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { getAdminRole } from '../../helpers/getAdminRole'
import { reportsFuels } from '../../request/fuels'
import { Card } from '../home'
import { TitleComponent } from '../../components/title'
import { getPrintReports } from '../../helpers/getPrintReports'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { format } from 'date-fns'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getUserNameOrCompany } from '../../helpers/getUserNameOrCompany'
import { getEmployeeRole } from '../../helpers/getEmployeeRole'
import { useNavigate } from 'react-router'
import { Row } from './components/Row'

export const ReportsHeadTable = () => {
  const { data: petrolStation }: { data: any } = useRequest({
    url: '/petrol-stations',
  })
  const [fuels, setFuels] = React.useState<any>([])
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      petrolStationID: getEmployeeRole() ? getPetrolStation('id') : null,
    },
  })

  React.useEffect(() => {
    let params = {}
    if (getEmployeeRole()) {
      params = { ...params, petrolStationID: getPetrolStation('id') }
    }
    reportsFuels(params).then(data => setFuels(data?.data))
  }, [])

  const resetFilter = () => {
    reset()
    let params = {}
    if (getEmployeeRole()) {
      params = { ...params, petrolStationID: getPetrolStation('id') }
    }
    reportsFuels(params).then(data => setFuels(data?.data))
  }

  const onSubmit = (values: any) => {
    reportsFuels({ ...values }).then(data => setFuels(data?.data))
  }

  return (
    <React.Fragment>
      <div className={'na-filter'} style={{ marginBottom: 30 }}>
        <FormControl fullWidth className={'mui-input'}>
          <div className={'na-filter__fields'}>
            {getAdminRole() && (
              <div>
                <TextInputSelectRa
                  name={'petrolStationID'}
                  control={control}
                  label={'Станция АЗС'}
                  errors={errors}
                  options={
                    petrolStation?.items?.map((item: any) => ({
                      id: item.id,
                      name: item.title,
                    })) || []
                  }
                />
              </div>
            )}
            <div>
              <TextInputDateRa
                name={'dateFrom'}
                control={control}
                label={'Дата от'}
                errors={errors}
              />
            </div>
            <div>
              <TextInputDateRa
                name={'dateTo'}
                control={control}
                label={'Дата до'}
                errors={errors}
              />
            </div>
          </div>

          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Применить
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={resetFilter}
            >
              Очистить
            </Button>
          </div>
        </FormControl>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {fuels?.totalFuelConsumptions?.length ? (
            <TitleComponent title={'Общий итог: '} />
          ) : (
            ''
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {fuels &&
              //@ts-ignore
              fuels?.totalFuelConsumptions?.map((fuel: any) => (
                <div
                  style={{ marginRight: 10, marginBottom: 10 }}
                  key={fuel?.id}
                >
                  <Card
                    value={`${Math.abs(fuel?.literAmountSum)} литров`}
                    title={fuel?.fuel}
                  />
                </div>
              ))}
          </div>
          <div style={{ width: 200, marginTop: 40 }}>
            <Button
              variant="contained"
              className={'btn-default btn-green bnt-icon-ok'}
              onClick={() => {
                let filter: any = []
                Object.entries(getValues()).map(item => {
                  switch (item[0]) {
                    case 'dateTo': {
                      if (item[1]) {
                        return filter.push({
                          title: 'Дата до',
                          value: format(new Date(item[1]), 'dd.MM.yyyy'),
                        })
                      } else {
                        return ''
                      }
                    }
                    case 'dateFrom': {
                      if (item[1]) {
                        return filter.push({
                          title: 'Дата от',
                          value: format(new Date(item[1]), 'dd.MM.yyyy'),
                        })
                      } else {
                        return ''
                      }
                    }
                    case 'petrolStationID': {
                      if (item[1]) {
                        return filter.push({
                          title: 'Станция АЗС',
                          value:
                            petrolStation?.items?.filter(
                              (station: any) =>
                                Number(station.id) === Number(item[1])
                            )?.[0]?.title || getPetrolStation('all'),
                        })
                      } else {
                        return ''
                      }
                    }
                  }
                })
                getPrintReports({
                  //@ts-ignore
                  reports: fuels,
                  filter: filter,
                })
              }}
            >
              Печать отчета
            </Button>
          </div>
        </div>
        {fuels?.totalFuelConsumptions?.length ? (
          <TitleComponent title={'Детализация: '} />
        ) : (
          ''
        )}

        <div className={'cards-block'}>
          {fuels?.customerFuelConsumptions?.map((item: any, key: number) => {
            return (
              <Row
                key={key}
                fuels={item?.fuelConsumptions}
                title={`${key + 1}) ${getUserNameOrCompany(item.customer)}`}
                customerId={item.customer.id as number}
              />
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}
