import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const schema = yup
  .object({
    lastName: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    firstName: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    password: yup
      .string()
      .min(6, 'Минимальная длинна пароля 6 символов')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    username: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    roleIds: yup.string().required('Обязательно к заполнению'),
    phone: yup
      .string()
      .matches(phoneRegExp, 'Укажите телефон')
      .required('Обязательно к заполнению'),
  })
  .required()

export const schemaChangePassword = yup
  .object({
    password: yup
      .string()
      .min(6, 'Минимальная длинна 6 символов')
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
