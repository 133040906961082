import { Button } from '@mui/material'
import React from 'react'
import { TitleComponent } from '../../components/title'
import { WorkingShiftContext } from '../../context/workingShiftProvider'
import { getActiveWorkingShiftID } from '../../helpers/getActiveWorkingShiftID'
import { reportFuel } from '../../request/fuels'
import { StickyHeadTable } from '../active-card/table'
import FormCard from './form-card'
import './style.scss'

export const Card = ({ value, title }: { value?: string; title: string }) => (
  <div
    className={'home__widgets__block'}
    style={{ backgroundImage: 'url(/assets/background/circle.svg)' }}
  >
    <div className={'home__widgets__block__value'}>{value || '-'}</div>
    <div className={'home__widgets__block__title'}>{title}</div>
  </div>
)

const HomePage = () => {
  const [open, setOpen] = React.useState(false)
  const [transaction, setTransaction] = React.useState(false)
  const [fuels, setFuels] = React.useState([])

  React.useEffect(() => {
    const balances = JSON.parse(localStorage.getItem('fuels') as any)
    if (balances && Number(balances.id) === Number(getActiveWorkingShiftID())) {
      setFuels(balances?.fuels)
    } else {
      localStorage.removeItem('fuels')
      getReport()
    }
  }, [])

  function getReport() {
    const activeWorkingShiftID = getActiveWorkingShiftID()
    if (activeWorkingShiftID) {
      reportFuel({ workingShiftID: activeWorkingShiftID }).then(data => {
        setFuels(data.data.totalFuelConsumptions)
        localStorage.setItem(
          'fuels',
          JSON.stringify({
            id: getActiveWorkingShiftID(),
            fuels: data.data.totalFuelConsumptions,
          })
        )
      })
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const { isOpenWorkingShift }: any = React.useContext(WorkingShiftContext)

  React.useEffect(() => {
    if (!isOpenWorkingShift) {
      setTransaction(false)
    }
  }, [isOpenWorkingShift])

  return (
    <React.Fragment>
      {open && <FormCard open={open} handleClose={handleClose} />}
      <div className={'home container'}>
        <TitleComponent title={'Рабочий стол'} />
        <div className={'home__widgets'}>
          {fuels.map((fuel: any) => (
            <Card
              key={fuel?.id}
              value={`${
                Math.trunc(Math.abs(fuel?.literAmountSum) * 100) / 100
              } литров`}
              title={fuel?.fuel}
            />
          ))}
        </div>
        <div className={'home__working-block'}>
          <Button
            onClick={handleOpen}
            variant="contained"
            style={{ opacity: isOpenWorkingShift ? 1 : 0.5 }}
            className={'btn-default btn-green'}
            disabled={!isOpenWorkingShift}
          >
            Заправить клиента
          </Button>
        </div>
        <TitleComponent
          style={{ fontSize: 24 }}
          title={'Операции за текущую смену'}
        />
        {!transaction && (
          <div className={'transactions'}>
            <Button
              onClick={() => setTransaction(true)}
              variant="contained"
              className={'btn-default btn-blue'}
              style={{ width: '200px', opacity: isOpenWorkingShift ? 1 : 0.5 }}
              sx={{ width: '200px' }}
              disabled={!isOpenWorkingShift}
            >
              Показать транзакции
            </Button>
          </div>
        )}
        {transaction && <StickyHeadTable getReport={getReport} />}
      </div>
    </React.Fragment>
  )
}

export default HomePage
