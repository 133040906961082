import { Client } from '../types/clients'

export function getDiscount(item: Client) {
  switch (item.discountType) {
    case 1:
      return `${item.discount} %`
    case 2:
      return `${item.discount} ₽`
    default:
      return '-'
  }
}

export function getDiscountType(data: any) {
  if (data.discountType === 2) {
    return 'Скидка в рублях с литра'
  } else if (data.discountType === 1) {
    return 'Скидка в процентах с литра'
  } else {
    return '-'
  }
}
