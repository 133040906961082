import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const schemaUpdate = yup
  .object({
    lastName: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    firstName: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    username: yup
      .string()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    roleIds: yup.string().required('Обязательно к заполнению'),
  })
  .required()
