import React from 'react'
import { TitleComponent } from '../../components/title'
import { useRequest } from '../../request/use-request'
import './style.scss'
import { ShiftsHeadTable } from './table'

const ShiftsPage = () => {
  return (
    <div className={'container'} style={{ marginTop: 40 }}>
      <TitleComponent title={'Смены'} />
      <ShiftsHeadTable />
    </div>
  )
}
export default ShiftsPage
