import { Button, FormControl } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { TextInputRa } from '../../components/inputs/text-field'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { TypeTransaction } from '../../const/type-transactions'
import { phoneClear } from '../../formatters/clear-phone'
import { useRequest } from '../../request/use-request'
import { PatrolStation } from '../../types/patrol-station'
import { Role } from '../../types/role'

type Props = {
  setPage: (value: number) => void
  getDefaultFilter: () => void
  navigate: (url: string) => void
  location: any
}

export const Filter = (props: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fio: '',
      phone: '',
      roleID: null,
      username: '',
      petrolStationID: null,
    },
  })
  const { data }: { data: PatrolStation | any } = useRequest({
    url: '/petrol-stations',
  })
  const { data: roles }: { data: { items: Role[] } | any } = useRequest({
    url: '/roles',
  })
  const resetFilter = () => {
    reset({
      fio: '',
      phone: '',
      roleID: null,
      username: '',
      petrolStationID: null,
    })
    props.setPage(0)
    props.getDefaultFilter()
  }
  const onSubmit = (values: any) => {
    let params = new URLSearchParams(props.location.search)
    for (let i = 0; i < Object.keys(values).length; i++) {
      Object.entries(values).map((value, key) => {
        if (value[1] !== null && String(value[1]).length) {
          if (value[0] === 'phone') {
            if (String(phoneClear((value[1] as string) || ''))) {
              params.set(value[0], phoneClear(value[1] as any) as string)
            }
          } else {
            params.set(value[0], value[1] as string)
          }
        }
      })
    }
    params.set('page', '1')
    props.setPage(0)
    props.navigate(`/users?${params.toString()}`)
  }
  return (
    <div className={'na-filter'} style={{ marginBottom: 30 }}>
      <FormControl fullWidth className={'mui-input'}>
        <div className={'na-filter__fields'}>
          <div>
            <TextInputRa
              name={'fio'}
              label={'ФИО'}
              control={control}
              errors={errors}
              placeholder={'Введите фио'}
            />
          </div>
          <div>
            <TextInputPhoneRa
              name={'phone'}
              control={control}
              label={'Телефон'}
              errors={errors}
            />
          </div>
          <div>
            <TextInputRa
              name={'username'}
              label={'Имя пользователя'}
              control={control}
              errors={errors}
              placeholder={'Введите имя пользователя'}
            />
          </div>
          {data && (
            <div>
              <TextInputSelectRa
                name={'petrolStationID'}
                control={control}
                label={'Станция АЗС'}
                errors={errors}
                options={
                  data?.items?.map((item: any) => ({
                    id: item.id,
                    name: item.title,
                  })) || []
                }
              />
            </div>
          )}
          {roles && (
            <div>
              <TextInputSelectRa
                name={'roleID'}
                control={control}
                label={'Роль сотрудника'}
                errors={errors}
                options={
                  roles?.items?.map((item: any) => ({
                    id: item.id,
                    name: item.description,
                  })) || []
                }
              />
            </div>
          )}
        </div>
        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={() => {
              resetFilter()
              reset()
            }}
          >
            Очистить
          </Button>
        </div>
      </FormControl>
    </div>
  )
}
