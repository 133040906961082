type Props = {
  location: any
  values: any
  setPage: any
}
export function getParamsRequest(props: Props) {
  let params = new URLSearchParams(props.location.search)
  for (let i = 0; i < Object.keys(props.values).length; i++) {
    Object.entries(props.values).map((value, key) => {
      if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
        //@ts-ignore
        params.set(value[0], new Date(value[1]).toJSON())
      } else if (value[1]) {
        params.set(value[0], value[1] as string)
      }
    })
  }
  params.set('page', '1')
  props.setPage(0)
  return params.toString()
}
