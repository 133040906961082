import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TitleComponent } from '../../components/title'
import { CustomerCreate, permission } from '../../permission'
import ListClients from './list'
import './style.scss'

const ClientPage = (props: any) => {
  const navigate = useNavigate()

  return (
    <div className={'clients container'}>
      <div className="clients__toolbar">
        <div>
          <TitleComponent title={'Список клиентов'} />
        </div>
        <div>
          {permission(CustomerCreate) && (
            <Button
              className={' btn-default btn-gray'}
              onClick={() => navigate('create')}
            >
              Добавить клиента
            </Button>
          )}
        </div>
      </div>
      <ListClients />
    </div>
  )
}

export default ClientPage
