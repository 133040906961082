import axios from 'axios'
import env from '../env'
import { Client } from '../types/clients'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const addedCards = (props: { customerID: number; number: string }) => {
  return baseURL().post(`cards`, { ...props })
}
export const customerListCards = (props: { customerID: number }) => {
  return baseURL().get(`cards?customerID=${props.customerID}`)
}

export const firstCards = (props: { number: string; pinCode: string }) => {
  return baseURL().get(`cards/${props.number}?pinCode=${props.pinCode}`)
}
export const updatePinCode = (props: { number: string; pinCode: string }) => {
  return baseURL().patch(`cards/${props.number}`, { pinCode: props.pinCode })
}

export const updateStatusCard = (props: { number: string; status: string }) => {
  return baseURL().patch(`cards/${props.number}`, { status: props.status })
}
export const deleteCard = (props: { uuid: string }) => {
  return baseURL().delete(`cards/${props.uuid}`)
}
