import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../../../components/inputs/text-field'
import { style } from '../../../../styles/style'
import { schemaCorrect, schemaFuel } from '../../schema'
import { TextInputSelectRa } from '../../../../components/inputs/select-field'
import { correctBalance } from '../../../../request/clients'
import { getLiter } from '../../../../helpers/getLiter'
import { getAmount } from '../../../../helpers/getAmount'
import { Balance } from '../../../../types/balance'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues?: {
    uuid?: string
  }
  balances: Balance[]
  uuid: string | null
  onSuccess?: () => void
}

const FormCorrectBalance = ({
  open,
  handleClose,
  defaultValues,
  balances,
  uuid,
  onSuccess,
}: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCorrect),
    defaultValues: {
      liter: '',
      uuid: uuid,
      id: null,
      amount: null,
    },
  })

  function closeModal() {
    handleClose()
    reset({
      liter: '',
      uuid: null,
      id: null,
      amount: null,
    })
  }
  const onSubmit = (values: any) => {
    correctBalance({ ...values, uuid }).then(() => {
      closeModal()
      onSuccess && onSuccess()
    })
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Корректировать баланс карты
        </h2>
        <FormControl fullWidth className={'mui-input'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputSelectRa
              name={'id'}
              control={control}
              label={'Выберите топливо'}
              errors={errors}
              options={
                (balances &&
                  balances?.map((balance: any) => ({
                    id: balance.id,
                    name: `${balance.fuel.title} (${balance.purchasePrice} руб/л)`,
                  }))) ||
                []
              }
            />
            <TextInputRa
              name={'amount'}
              label={'Оставить на балансе'}
              control={control}
              errors={errors}
              type={'number'}
              disabled={!watch('id')}
              onChange={value => {
                const number = value.target.value
                const price = balances?.find(
                  (balance: any) => String(balance.id) === String(watch('id'))
                )?.purchasePrice

                return setValue('liter', getLiter(number, price as any) as any)
              }}
              placeholder={'Введите сумму'}
            />
            <TextInputRa
              name={'liter'}
              label={'Оставить на балансе литров'}
              control={control}
              errors={errors}
              type={'number'}
              disabled={!watch('id')}
              onChange={value => {
                const number = value.target.value
                const price = balances?.find(
                  (balance: any) => String(balance.id) === String(watch('id'))
                )?.purchasePrice

                return setValue(
                  'amount',
                  getAmount(number, price as any) as any
                )
              }}
              placeholder={'Введите кол-во литров'}
            />
            <div className={'station-form__block-buttons'}>
              <Button
                type={'submit'}
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={closeModal}
              >
                Отмена
              </Button>
            </div>
          </form>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormCorrectBalance
