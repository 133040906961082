import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { Button } from '@mui/material'
import { getUserName } from '../../helpers/getUserName'
import { useForm } from 'react-hook-form'
import { getPrintReport } from '../../helpers/getPrintReport'
import { Filter } from './filter'
import { useFilters } from '../../filters'

const defaultFilter = {
  dateTo: endOfMonth(new Date()).toJSON(),
  dateFrom: startOfMonth(new Date()).toJSON(),
}

interface Column {
  id:
    | 'id'
    | 'employee'
    | 'petrolStation'
    | 'isActive'
    | 'closedAt'
    | 'startedAt'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number, status?: boolean) => string
}

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'Номер смены',
  },
  { id: 'employee', label: 'Открыл смену' },
  {
    id: 'petrolStation',
    label: 'АЗС',
  },
  {
    id: 'isActive',
    label: 'Cтатус',
    //@ts-ignore
    format: (status: boolean) => (status ? 'Открыта' : 'Закрыта'),
  },
  {
    id: 'startedAt',
    label: 'Дата начала',
    minWidth: 100,
    format: (value: number) => format(new Date(value), 'dd.MM.yyyy в HH:mm'),
  },
  {
    id: 'closedAt',
    label: 'Дата окончания',
    //@ts-ignore
    format: (value: number, status) => {
      return status ? '-' : format(new Date(value), 'dd.MM.yyyy в HH:mm')
    },
  },
  {
    id: 'id',
    label: '',
    //@ts-ignore
    format: value => (
      <Button
        variant="contained"
        className={'btn-default btn-blue bnt-icon-ok'}
        onClick={() => getPrintReport(value)}
      >
        Получить отчет
      </Button>
    ),
  },
]

interface Data {
  id: number
  employee: string
  petrolStation: string
  isActive: boolean
  closedAt: Date
  startedAt: Date
}

function createData(
  id: number,
  employee: string,
  petrolStation: string,
  isActive: boolean,
  closedAt: Date,
  startedAt: Date
): Data {
  return {
    id,
    employee,
    petrolStation,
    isActive,
    closedAt,
    startedAt,
  }
}

export const ShiftsHeadTable = () => {
  const {
    data,
    getDefaultFilter,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
    page,
    rowsPerPage,
    location,
    navigate,
  } = useFilters({
    url: 'report-shifts',
    resource: 'working-shifts',
    defaultFilter,
  })

  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      petrolStationID: null,
      cardNumber: null,
      customerID: null,
    },
  })

  if (!data) {
    return <div>Loading...</div>
  }
  return (
    <React.Fragment>
      <div className={'na-filter'} style={{ marginBottom: 30 }}>
        <Filter
          setPage={setPage}
          navigate={navigate}
          getDefaultFilter={getDefaultFilter}
          location={location}
          defaultFilter={{
            dateTo: endOfMonth(new Date()),
            dateFrom: startOfMonth(new Date()),
          }}
        />
      </div>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.items
                  ?.map((item: any) =>
                    createData(
                      item.id,
                      getUserName({ ...item?.employee }),
                      item?.petrolStation?.title || '-',
                      item.isActive,
                      item.closedAt,
                      item.startedAt
                    )
                  )
                  .map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map(column => {
                          const value = row[column.id]

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(value as any, row['isActive'])
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={data?.totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </React.Fragment>
  )
}
