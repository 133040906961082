import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TitleComponent } from '../../components/title'
import { permission, PetrolStationCreate } from '../../permission'
import { petrolStations } from '../../request'
import ListStations from './list'
import './style.scss'

const StationPage = (props: any) => {
  const navigate = useNavigate()
  const [list, setList] = React.useState([])

  React.useEffect(() => {
    petrolStations().then(data => setList(data.data?.items))
  }, [])

  return (
    <div className={'stations container'}>
      <div className="stations__toolbar">
        <div>
          <TitleComponent title={'Заправочные станции'} />
        </div>
        <div>
          {permission(PetrolStationCreate) && (
            <Button
              className={' btn-default btn-gray'}
              onClick={() => navigate('create')}
            >
              Добавить станцию
            </Button>
          )}
        </div>
      </div>
      <ListStations list={list} setList={setList} />
    </div>
  )
}

export default StationPage
