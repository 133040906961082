import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { ModalDelete } from '../../components/modal-delete'
import { deleteClients } from '../../request/clients'
import { CustomerDelete, CustomerUpdate, permission } from '../../permission'
import { Filter } from './filter'
import { useFilters } from '../../filters'
import { getDiscount } from '../../helpers/getDiscountType'

interface Column {
  id:
    | 'fio'
    | 'phone'
    | 'subjectForm'
    | 'companyTitle'
    | 'companyInn'
    | 'id'
    | 'discount'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

interface Data {
  fio: string
  subjectForm: 0 | 1 | 2
  phone: string
  companyTitle: string
  companyInn: string
  id: number
}

const ListClients = (props: {}) => {
  const {
    data,
    getDefaultFilter,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
    page,
    rowsPerPage,
    location,
    navigate,
    request,
  } = useFilters({ url: 'clients', resource: 'customers' })

  const [isDelete, setIsDelete] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState<Number | null>(null)

  const handleOpen = () => setIsDelete(true)

  const checkIdDelete = (id: number) => {
    handleOpen()
    setIdDelete(id)
  }
  const handleDelete = () =>
    deleteClients({ id: idDelete as number }).then(() => {
      request(location.search)
      handleClose()
    })

  const handleClose = () => setIsDelete(false)

  const columns: readonly Column[] = [
    { id: 'fio', label: 'ФИО', minWidth: 200 },
    { id: 'phone', label: 'Телефон для связи', minWidth: 100 },
    {
      id: 'discount',
      label: 'Размер скидки',
      minWidth: 150,
      align: 'right',
      //@ts-ignore
      format: value => value || '-',
    },
    {
      id: 'subjectForm',
      label: 'Юридическое лицо',
      minWidth: 100,
      align: 'right',
      format: value => (Number(value) === 2 ? 'Да' : 'Нет'),
    },
    {
      id: 'companyTitle',
      label: 'Наименование компании',
      minWidth: 150,
      align: 'right',
      //@ts-ignore
      format: value => value || '-',
    },
    {
      id: 'companyInn',
      label: 'ИНН компании',
      minWidth: 150,
      align: 'right',
      //@ts-ignore
      format: value => value || '-',
    },
    {
      id: 'id',
      label: '',
      minWidth: 120,
      align: 'right',
      //@ts-ignore
      format: value => (
        <div className={'icons-delete-edit'}>
          {permission(CustomerUpdate) && (
            <img
              onClick={() => navigate(`edit/${value}`)}
              src={'/assets/icons/edit.svg'}
            />
          )}
          {permission(CustomerDelete) && (
            <img
              onClick={() => checkIdDelete(value)}
              src={'/assets/icons/delete.svg'}
            />
          )}
        </div>
      ),
    },
  ]
  return (
    <>
      <ModalDelete
        handleClose={handleClose}
        handleDelete={handleDelete}
        open={isDelete}
      />
      <Filter
        setPage={setPage}
        navigate={navigate}
        getDefaultFilter={getDefaultFilter}
        location={location}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.items
                ?.map((item: any) => ({
                  ...item,
                  fio:
                    item.lastName +
                    ' ' +
                    item.firstName +
                    ' ' +
                    item?.middleName,
                  discount: getDiscount(item),
                }))
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        //@ts-ignore
                        const value = row[column.id]
                        return (
                          <TableCell
                            onClick={() =>
                              column.id !== 'id' &&
                              navigate(`/clients/${row.id}`)
                            }
                            style={{ cursor: 'pointer' }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.format
                              ? column.format(value as any)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={data?.total_count || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </>
  )
}

export default ListClients
