import React from 'react'
import { TitleComponent } from '../../components/title'
import { StatusCard } from '../../hooks/useStatus'
import './style.scss'
import { StickyHeadTable } from './table'
import { Balance } from '../../types/balance'

const TransactionsPage = () => {
  return (
    <React.Fragment>
      <div style={{ paddingBottom: 40 }}>
        <div className={'container'}>
          <TitleComponent title={'Список транзакций'} />
          <StickyHeadTable />
        </div>
      </div>
    </React.Fragment>
  )
}
export default TransactionsPage
