import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { TextMaskNumberCard } from '../mask/mask-card-number'

export const TextInputNumberCardRa = (props: {
  name: string
  control: any
  label: string
  errors: any
  placeholder?: string
  autoFocus?: boolean
  onKeyDown?: (e: any) => void
  disabled?: boolean
  focused?: boolean
  id?: string
  type?: string
  autoComplete?: string
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <div className={'mui-input'}>
          <label className={'mui-input__label small-text'}>{props.label}</label>
          <TextField
            {...props}
            {...field}
            label={''}
            autoFocus={props.autoFocus}
            className={'mui-input__field'}
            placeholder={props.placeholder || 'Введите текст'}
            error={!!props.errors[field.name]?.message}
            helperText={props.errors[field.name]?.message}
            InputProps={{
              inputComponent: TextMaskNumberCard,
            }}
          />
        </div>
      )}
    />
  )
}
