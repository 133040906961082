import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Modal,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { style } from '../../styles/style'
import { schemaFuel } from './schema'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { Balance } from '../../types/balance'
import { writeOffBalance } from '../../request/clients'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import { getActiveWorkingShiftID } from '../../helpers/getActiveWorkingShiftID'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getPrintCheck } from '../../helpers/getPrintCheck'
import { getUserNameLocalStorage } from '../../helpers/getUserNameLocalStorage'
import { getLiter } from '../../helpers/getLiter'
import { getAmount } from '../../helpers/getAmount'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues?: {
    uuid?: string
    number?: string
  }
  balances: Balance[]
}

const FormWriteOff = ({
  open,
  handleClose,
  defaultValues,
  balances,
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaFuel),
    defaultValues: {
      ...defaultValues,
      id: null,
      literAmount: null,
      summaAmount: null,
    },
  })

  const navigate = useNavigate()

  function closeModal() {
    handleClose()
    reset()
  }

  function setBalances(title: string, value: string) {
    let newFuels: any = []
    let fuels = JSON.parse(localStorage.getItem('fuels') as any)?.fuels

    if (!fuels?.some((fuel: any) => fuel?.fuel === title)) {
      newFuels.push({ fuel: title, literAmountSum: `-${value}` })
    }
    fuels &&
      fuels?.map((item: any) => {
        let sum = item.literAmountSum
        if (item?.fuel === title) {
          sum = Number(sum) - Number(value)
          return newFuels.push({ fuel: title, literAmountSum: sum })
        } else {
          newFuels.push({ ...item })
        }
      })
    return localStorage.setItem(
      'fuels',
      JSON.stringify({
        id: getActiveWorkingShiftID(),
        fuels: newFuels,
      })
    )
  }

  const onSubmit = (values: any) => {
    const balance_title = balances.filter(
      balance => balance.id === Number(values.id)
    )[0]?.fuel?.title
    setIsLoading(true)
    writeOffBalance({ ...values, uuid: defaultValues?.uuid })
      .then(data => {
        getPrintCheck({
          station: {
            id: getPetrolStation('id'),
            title: getPetrolStation('title'),
            address: getPetrolStation('address'),
          },
          id_check: data.data.id,
          number: defaultValues?.number,
          date: format(new Date(), 'dd.MM.yyyy в HH:mm'),
          operator: getUserNameLocalStorage(),
          write: {
            title: balance_title,
            amount: Math.abs(data.data.literAmount),
          },
          balance: {
            title: balance_title,
            amount: data.data.remainderLiterAmount,
          },
        })
        //@ts-ignore
        setBalances(balance_title, Math.abs(data.data.literAmount))
        closeModal()
        return navigate('/')
      })
      .catch(e => {
        if (e.response?.data?.description.literAmount) {
          setError('literAmount', {
            message: e.response?.data?.description.literAmount,
          })
        } else {
          setError('literAmount', { message: e.response?.data?.description })
        }
      })
      .finally(() => setIsLoading(false))
  }

  function getBalance(id?: number, params?: string) {
    if (id && balances && params) {
      return Number(
        //@ts-ignore
        balances.find(balance => Number(balance.id) === Number(id))[params]
      )
    } else return '-'
  }

  const updateLiterAmount = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      if (value.target.value && watch('id')) {
        const literPrice = balances.find(value => value.id === watch('id'))
          ?.literPrice as any
        setValue(
          'summaAmount',
          //@ts-ignore
          getAmount(Number(value.target.value), Number(literPrice))
        )
      }
    },
    [balances, setValue, watch]
  )

  const updateSummaAmount = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      if (value.target.value && watch('id')) {
        const literPrice = balances.find(
          value => value.id === watch('id')
        )?.literPrice
        //@ts-ignore
        setValue(
          'literAmount',
          //@ts-ignore
          getLiter(Number(value.target.value), Number(literPrice))
        )
      }
    },
    [balances, setValue, watch]
  )

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 450 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Списание баланса
        </h2>
        <FormControl fullWidth className={'mui-input'}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <TextInputSelectRa
                name={'id'}
                control={control}
                label={'Вид топлива'}
                errors={errors}
                options={balances.map((item: Balance) => ({
                  id: item.id,
                  name: `${item.fuel.title}  (${getBalance(
                    item.id,
                    'literAmount'
                  )} л.)`,
                }))}
              />
            </div>
            <div className={'input-balance'} style={{ flex: 1 }}>
              <div className={'input-balance__title'}>Баланс</div>
              <div
                className={'input-balance__value'}
                style={{ fontSize: 12, lineHeight: '16px' }}
              >
                {getBalance(watch('id') || 0, 'literAmount')} л. <br />
                {getBalance(watch('id') || 0, 'amount')} ₽{' '}
              </div>
            </div>
          </div>
          <TextInputRa
            name={'literAmount'}
            label={'Кол-во в литрах'}
            placeholder={'Укажите кол-во литров'}
            control={control}
            type="number"
            errors={errors}
            disabled={!watch('id')}
            onChange={updateLiterAmount}
          />
          <TextInputRa
            name={'summaAmount'}
            label={'Сумма в рублях'}
            placeholder={'Укажите сумму в рублях'}
            control={control}
            errors={errors}
            type="number"
            disabled={!watch('id')}
            onChange={updateSummaAmount}
          />
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className={'station-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                Списать
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={closeModal}
                disabled={isLoading}
              >
                Отмена
              </Button>
            </div>
          )}
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormWriteOff
