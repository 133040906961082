import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Balance } from '../../../../types/balance'
import FormAddedFuel from '../../added-fuel'
import FormCorrectBalance from './correct-fuel'
import './style.scss'

type Props = {
  balances: Balance[]
  uuid: string
}

export const ToolbarCard = (props: Props) => {
  const { balances, uuid } = props

  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()

  const [isCorrect, setCorrect] = useState<{
    status: boolean
    uuid: string
  }>({
    status: false,
    uuid: uuid,
  })
  const [isAddedFuel, setAddedFuel] = useState<{
    status: boolean
    uuid: string
  }>({
    status: false,
    uuid: uuid,
  })

  function handleOpenCorrect() {
    setCorrect({ status: true, uuid })
  }
  function handleCloseCorrect() {
    setCorrect({ status: false, uuid })
  }
  function handleOpenModalAddedFuel() {
    setAddedFuel({ status: true, uuid })
  }
  function handleCloseModalAddedFuel() {
    setAddedFuel({ status: false, uuid })
  }

  return (
    <div className={'client-card-toolbar'}>
      <div className={'client-card-toolbar__modals'}>
        <FormCorrectBalance
          open={isCorrect.status}
          handleClose={handleCloseCorrect}
          balances={balances || []}
          uuid={uuid}
          onSuccess={() =>
            navigate(`/clients/${id}${location.search}`, {
              state: {
                updateList: new Date().toString(),
              },
            })
          }
        />
        <FormAddedFuel
          open={isAddedFuel.status}
          handleClose={handleCloseModalAddedFuel}
          defaultValues={{ uuid }}
          onSuccess={() =>
            navigate(`/clients/${id}${location.search}`, {
              state: {
                updateList: new Date().toString(),
              },
            })
          }
        />
      </div>
      <div className={'client-card-toolbar__buttons'}>
        <Button
          variant="contained"
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={() => handleOpenModalAddedFuel()}
        >
          Пополнить баланс
        </Button>
        <Button
          variant="contained"
          className={'btn-default btn-blue bnt-icon-ok'}
          onClick={() => handleOpenCorrect()}
        >
          Коррекция баланса
        </Button>
      </div>
    </div>
  )
}
