import { CircularProgress } from '@mui/material'
import React from 'react'
import {
  getDiscount,
  getDiscountType,
} from '../../../../helpers/getDiscountType'
import { getFormatDate } from '../../../../helpers/getFormatDate'
import { getUserName } from '../../../../helpers/getUserName'
import { useRequest } from '../../../../request/use-request'
import { Client } from '../../../../types/clients'

const CardElem = (props: {
  value: string | number | undefined
  title: string
}) => (
  <div className={'card-petrol'}>
    <div className={'card-petrol__title'}>{props.title}:</div>
    <div className={'card-petrol__value'}>{props.value}</div>
  </div>
)

type Props = {
  client: Client
}

export const ClientInfo = (props: Props) => {
  const { client } = props

  return (
    <>
      <div>
        <CardElem title={'id'} value={client.id} />
        <CardElem title={'ФИО'} value={getUserName(client)} />
        <CardElem title={'Телефон'} value={client.phone} />
        <CardElem title={'Тип скидки'} value={getDiscountType(client)} />
        <CardElem title={'Размер скидки'} value={getDiscount(client)} />
      </div>
      <div>
        <CardElem
          title={'Юр. лицо'}
          value={client.subjectForm === 1 ? 'Нет' : 'Да'}
        />
        <CardElem
          title={'Дата создания'}
          value={getFormatDate({
            date: client.createdAt as string,
            type: 'dd.MM.yyyy в HH:mm',
          })}
        />
      </div>
      <div>
        {client.subjectForm === 2 && (
          <CardElem
            title={'Наименование компании'}
            value={client.companyTitle}
          />
        )}
        {client.subjectForm === 2 && (
          <CardElem title={'ИНН'} value={client.companyInn} />
        )}
      </div>
    </>
  )
}
