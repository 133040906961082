import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Button } from '@mui/material'
import { useRequest } from '../../request/use-request'
import { getUserName } from '../../helpers/getUserName'
import FormRefunds from './form-refunds'
import { Transactions } from '../../formatters/type-transaction'
import { formatMoney } from '../../formatters/formatMoney'
import { formatNumber } from '../../formatters/format'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import { getUserNameOrCompany } from '../../helpers/getUserNameOrCompany'
import { getActiveWorkingShiftID } from '../../helpers/getActiveWorkingShiftID'
import { searchTransactions } from '../../request/transaction'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getLiterAmount } from '../../formatters/liter-amount'
import { getCheckPrintComponent } from '../../components/checks'
import { getFormatDate } from '../../helpers/getFormatDate'

interface Column {
  id:
    | 'id'
    | 'responsible'
    | 'balance'
    | 'type_transaction'
    | 'fuel'
    | 'time_and_date'
    | 'card_number'
    | 'sum'
    | 'client'

  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number, params?: any, type?: any, data_print?: any) => string
}

const columns: readonly Column[] = [
  {
    id: 'card_number',
    label: 'Номер карты',
    format: value => formatNumber(value, ' '),
  },
  {
    id: 'client',
    label: 'Клиент',
    format: value => getUserNameOrCompany(value),
  },
  {
    id: 'type_transaction',
    label: 'Тип транзакции',
    format: value => Transactions(value),
  },
  { id: 'fuel', label: 'Вид топлива' },
  {
    id: 'sum',
    label: 'Литры',

    format: (value: number) => formatMoney(value) + ' л.',
  },
  {
    id: 'time_and_date',
    label: 'Время и Дата',
    //@ts-ignore
    format: (value: string) =>
      format(new Date(value), 'dd.MM.yyyy в HH:mm', { locale: ru }),
  },
  {
    id: 'id',
    label: '',
    maxWidth: 50,
    //@ts-ignore
    format: (
      id: number,
      handleOpen: (
        id: number,
        cardNumber: string,
        balanceTitle: string
      ) => void,
      type,
      data_print
    ) => (
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
      >
        {type !== 2 ? (
          <div />
        ) : (
          <Button
            className={'btn-default btn-blue'}
            onClick={() =>
              handleOpen(id, data_print?.card_number, data_print.fuel)
            }
          >
            Возврат
          </Button>
        )}
        <div
          onClick={() =>
            getCheckPrintComponent({
              operation_type: type,
              station: {
                title: data_print.petrolStation.title,
                address: data_print.petrolStation.address,
              },
              id_check: data_print?.id,
              number: data_print?.card_number,
              date: getFormatDate({
                date: data_print?.time_and_date,
                type: 'dd.MM.yyyy в HH:mm',
              }),
              operator: getUserName(data_print?.employee),
              write: {
                title: data_print.fuel,
                amount: getLiterAmount(Math.abs(data_print?.sum)),
              },
              balance: {
                title: data_print?.fuel,
                amount: getLiterAmount(data_print?.remainderLiterAmount),
              },
            })
          }
          title={'Печать чека'}
          className={'button-print'}
        >
          <LocalPrintshopIcon htmlColor={'white'} />
        </div>
      </div>
    ),
  },
]

export const StickyHeadTable = (props: { getReport: () => void }) => {
  const [open, setOpen] = React.useState<{
    showModal: boolean
    id: null | number
    cardNumber: null | string
    balanceTitle: null | string
  }>({ showModal: false, id: null, cardNumber: null, balanceTitle: null })

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [data, setData] = React.useState<any>({})

  function searchTransaction(filter: string) {
    return searchTransactions(filter).then(result => setData(result.data))
  }

  function getDefaultFilter(value?: string) {
    let params = new URLSearchParams(value || '?')
    if (getActiveWorkingShiftID()) {
      params.set('workingShiftID', getActiveWorkingShiftID() as string)
      params.set('operationTypes', JSON.stringify([2, 4]))
      params.set('petrolStationID', getPetrolStation('id') as string)
    }
    params.set('countPerPage', String(rowsPerPage))
    params.set('page', String(page + 1))
    searchTransaction('?' + params.toString())
  }

  React.useEffect(() => {
    getDefaultFilter()
  }, [])

  React.useEffect(() => {
    getDefaultFilter()
  }, [page, rowsPerPage, open.showModal])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function handleOpen(id: number, cardNumber: string, balanceTitle: string) {
    setOpen({ showModal: true, id: id, cardNumber: cardNumber, balanceTitle })
  }

  function handleClose() {
    setOpen({
      showModal: false,
      id: null,
      cardNumber: null,
      balanceTitle: null,
    })
  }

  return (
    <>
      <FormRefunds
        open={open.showModal}
        handleClose={handleClose}
        defaultValues={{
          id: open.id as number,
          numberCard: String(open.cardNumber),
          balanceTitle: String(open.balanceTitle),
        }}
        getReport={props.getReport}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.items
                  ?.map((item: any) => ({
                    ...item,
                    id: item.id,
                    client: item.card.customer,
                    responsible: getUserName({ ...item.employee }),
                    balance: 0,
                    type_transaction: item.operationType,
                    sum: item.literAmount,
                    time_and_date: item.createdAt,
                    card_number: item.card.number,
                    fuel: item?.fuel?.title || 'Удален',
                  }))
                  .map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(
                                    value as any,
                                    handleOpen as any,
                                    row['type_transaction'] as any,
                                    row
                                  )
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data?.totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </>
  )
}
