import React from 'react'
import { formatMoney } from '../../../../formatters/formatMoney'
import { getLiterAmount } from '../../../../formatters/liter-amount'
import { getLiter } from '../../../../helpers/getLiter'
import { Balance } from '../../../../types/balance'
import './style.scss'

type Props = {
  balances?: Balance[]
}

export const TableListBalances = (props: Props) => {
  const { balances } = props

  return (
    <div className={'table-list-balances'}>
      <div className={'table-list-balances__balance-header'}>
        <div className="small-text">Вид топлива</div>
        <div className="small-text">Цена за литр</div>
        <div className="small-text">Сумма</div>
        <div className="small-text">Кол-во литров</div>
      </div>
      {balances?.map(balance => (
        <div className={'table-list-balances__balance-body'} key={balance.id}>
          <div className={'small-text'}>{balance.fuel.title}</div>
          <div className={'small-text'}>{balance.purchasePrice} ₽/л</div>
          <div className={'small-text'}>{formatMoney(balance.amount)} ₽</div>
          <div className={'small-text'}>
            {getLiter(balance.amount, balance.purchasePrice)} л.
          </div>
        </div>
      ))}
    </div>
  )
}
