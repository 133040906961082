import axios from 'axios'
import env from '../env'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const workingShiftStart = () => {
  return baseURL().post(`working-shifts`)
}
export const workingShiftEnd = () => {
  return baseURL().delete(`working-shifts`)
}
