import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { TitleComponent } from '../../components/title'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import './style.scss'
import { style } from '../../styles/style'
import { useNavigate } from 'react-router'
import { Employee } from '../../types/employee'
import {
  listEmployee,
  listEmployeePetrol,
  updateEmployee,
} from '../../request/employee'
import { TextInputRa } from '../../components/inputs/text-field'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { phoneClear } from '../../formatters/clear-phone'
import { TextInputSelectCheckboxRa } from '../../components/inputs/select-checkbox-field'
import { useRequest } from '../../request/use-request'

const CardUser = (props: Employee & { watchEmployee: () => void }) => {
  return (
    <div className={'card-user'}>
      <div className={'card-user__fio medium-text'}>
        {props.lastName} {props.firstName} {props.middleName}
      </div>
      <div
        onClick={() =>
          updateEmployee({
            ...props,
            //@ts-ignore
            petrolStationID: null,
          }).then(() => props.watchEmployee())
        }
        className={'card-user__delete'}
      >
        <img src={'/assets/icons/delete.svg'} />
      </div>
    </div>
  )
}

type Props = {
  onSubmit: (props: any, setErrors?: any) => void
  title?: string
  defaultValues: {
    id?: number | null
    title?: string | null
    address?: string | null
    phone?: string | null
    fuelIDs: [number] | []
  }
}

const Form = (props: Props) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...props.defaultValues,
    },
  })

  const navigate = useNavigate()
  const [errorForm, setErrorForm] = React.useState({
    status: false,
    message: '',
  })
  const [open, setOpen] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [allUsers, setAllUsers] = React.useState<Employee[]>([])
  const [employee, setEmployee] = React.useState([])

  function watchEmployee() {
    listEmployeePetrol({ id: watch('id') as any }).then(data =>
      setEmployee(data.data.items)
    )
  }

  React.useEffect(() => {
    listEmployee().then(data => setAllUsers(data.data?.items || []))
    if (watch('id')) {
      listEmployee().then(data => setAllUsers(data.data?.items || []))
      watchEmployee()
    }
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    setUser(
      //@ts-ignore
      allUsers?.filter(
        item => Number(item.id) === Number(event.target.value)
      )[0]
    )
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const { data: fuels }: { data: any } = useRequest({ url: 'fuels' })

  const onSubmit = (values: any) => {
    props.onSubmit(
      { ...values, phone: phoneClear(values.phone) },
      (error: any) => {
        if (typeof error === 'string') {
          setErrorForm({ status: true, message: error })
          setTimeout(() => setErrorForm({ status: false, message: '' }), 3000)
        }
        if (error) {
          Object.entries(error).map((item: any) =>
            setError(item[0], { message: item[1] })
          )
        }
      }
    )
  }
  if (!fuels) {
    return <CircularProgress />
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errorForm.status && (
        <Alert variant="filled" severity="error">
          {errorForm.message}
        </Alert>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2
            className={'medium-text'}
            style={{ fontSize: 22, fontWeight: 'bold' }}
            id="medium-text"
          >
            Добавление сотрудников
          </h2>
          <FormControl fullWidth className={'mui-input'}>
            <label className={'mui-input__label small-text'}>
              Список сотрудников
            </label>
            <Select
              className={'mui-input__select'}
              //@ts-ignore
              value={user.id}
              onChange={handleChange}
              placeholder={'Выберите из списка'}
              input={<OutlinedInput placeholder={'Выберите из списка'} />}
              displayEmpty={true}
            >
              <MenuItem disabled value={''}>
                Выберите из списка
              </MenuItem>
              {allUsers.map((item: Employee) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.lastName} {item.firstName} {item.middleName}
                </MenuItem>
              ))}
            </Select>
            <div className={'station-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={() => {
                  handleClose()
                  //@ts-ignore
                  updateEmployee({
                    ...user,
                    //@ts-ignore
                    petrolStationID: watch('id'),
                  }).then(() => watchEmployee())
                }}
              >
                Добавить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={handleClose}
              >
                Отмена
              </Button>
            </div>
          </FormControl>
        </Box>
      </Modal>
      <div className={'station-form'}>
        <div className={'station-form__block-title'}>
          <TitleComponent title={props.title || ''} />
        </div>
        <div className={'station-form__block-two-column'}>
          <div className={'station-form__block-two-column__left'}>
            <TextInputRa
              name={'title'}
              control={control}
              label={'Наименование АЗС'}
              errors={errors}
            />
            <TextInputRa
              name={'address'}
              control={control}
              label={'Адрес АЗС'}
              errors={errors}
            />
            <TextInputPhoneRa
              name={'phone'}
              control={control}
              label={'Телефон для связи'}
              errors={errors}
            />
            {fuels?.items?.length && (
              <TextInputSelectCheckboxRa
                options={
                  fuels?.items?.map?.((fuel: any) => ({
                    id: fuel.id,
                    name: fuel.title,
                  })) || []
                }
                name={'fuelIDs'}
                control={control}
                label={'Виды топлива на АЗС'}
                errors={errors}
              />
            )}
            <div className={'station-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={() => navigate(-1)}
              >
                Отмена
              </Button>
            </div>
          </div>
          {watch('id') && (
            <div className={'station-form__block-two-column__right'}>
              <div
                className={
                  'station-form__block-two-column__right__block-button'
                }
              >
                <Button
                  variant="contained"
                  className={'btn-default btn-blue bnt-icon-ok'}
                  onClick={handleOpen}
                >
                  Добавить сотрудника
                </Button>
              </div>
              <div className={'station-form__block-two-column__right__table'}>
                <div
                  className={
                    'station-form__block-two-column__right__table__title'
                  }
                >
                  <div
                    className={
                      'station-form__block-two-column__right__table__title__field-fio small-text'
                    }
                  >
                    ФИО
                  </div>

                  <div
                    className={
                      'station-form__block-two-column__right__table__title__field-delete'
                    }
                  ></div>
                </div>
                <div
                  className={
                    'station-form__block-two-column__right__table__content'
                  }
                >
                  {employee?.map((item: Employee) => (
                    <CardUser
                      key={item.id}
                      {...item}
                      watchEmployee={watchEmployee}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </form>
  )
}
export default Form
