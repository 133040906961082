import React from 'react'
import { TitleComponent } from '../../components/title'
import { useRequest } from '../../request/use-request'
import './style.scss'
import { ReportsHeadTable } from './table'

const ReportsPage = () => {
  return (
    <div className={'container'} style={{ marginTop: 40 }}>
      <TitleComponent title={'Отчет о пролитых литрах за выбранный период'} />
      <ReportsHeadTable />
    </div>
  )
}
export default ReportsPage
