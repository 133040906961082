import { MenuItem, OutlinedInput, Select } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

export const TextInputSelectRa = (props: {
  name: string
  control: any
  label: string
  errors: any
  placeholder?: string
  options: { id?: string | number; name?: string | number }[] | []
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <div className={'mui-input'}>
          <label className={'mui-input__label small-text'}>{props.label}</label>
          <Select
            className={'mui-input__select'}
            placeholder={props.placeholder || 'Выберите из списка'}
            input={<OutlinedInput placeholder={'Выберите из списка'} />}
            displayEmpty={true}
            error={props.errors[props.name]}
            {...field}
          >
            <MenuItem value={''}>{props.placeholder || 'Не выбрано'}</MenuItem>
            {props.options &&
              props.options.map((role: any) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>
          <div className={'text-error'}>
            {props.errors[field.name]?.message}
          </div>
        </div>
      )}
    />
  )
}
