import { Button, FormControl } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextInputRa } from '../../../components/inputs/text-field'
import './style.scss'

type Props = {}

export const Filter = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  const idClient = id

  let params = new URLSearchParams(location.search)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      number: '',
      title: '',
    },
  })

  useEffect(() => {
    params.forEach((value, key) => {
      setValue(key as any, value)
    })
  }, [])

  const resetFilter = () => {
    reset({
      number: '',
      title: '',
    })

    navigate(`/clients/${idClient}`)
  }

  const onSubmit = (values: any) => {
    Object.entries(values).map(field => {
      params.delete(field[0])
      field[1] && params.append(field[0], String(field[1]))
    })
    navigate(`/clients/${idClient}?${params.toString()}`)
  }

  return (
    <div className={'na-filter'} style={{ marginBottom: 30 }}>
      <FormControl fullWidth className={'mui-input'}>
        <div className={'na-filter__fields'}>
          <div>
            <TextInputRa
              name={'number'}
              control={control}
              label={'Номер карты'}
              errors={errors}
              placeholder={'Введите номер карты'}
            />
          </div>
          <div>
            <TextInputRa
              name={'title'}
              control={control}
              label={'Название карты'}
              errors={errors}
              placeholder={'Введите название карты'}
            />
          </div>
        </div>
        <div className={'client-component-show-filter-button'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={handleSubmit(onSubmit)}
          >
            Найти
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={() => {
              resetFilter()
              reset()
            }}
          >
            Очистить
          </Button>
        </div>
      </FormControl>
    </div>
  )
}
