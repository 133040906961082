import { Station } from '../types/patrol-station'

export const getStation = (props: Station) => {
  let title = []
  if (props?.title) {
    title.push(props?.title)
  }
  if (props?.address) {
    title.push(props?.address)
  }
  return title.join(' ')
}

export const getShortNameStation = (props: Station) => {
  let title = []
  if (props?.title) {
    title.push(props?.title)
  }
  return title.join(' ')
}
