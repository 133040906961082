import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { TextInputRa } from '../../components/inputs/text-field'
import { firstCards } from '../../request/cards'
import { style } from '../../styles/style'
import { schemaCard } from './schema'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues?: {
    customerID: number
    number?: string
  }
}

const FormCard = ({ open, handleClose }: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCard),
    defaultValues: { number: null, pinCode: null },
  })

  const [disabled, setDisabled] = React.useState(false)
  const navigate = useNavigate()
  function closeModal() {
    handleClose()
    reset()
    setDisabled(false)
  }

  const onSubmit = (values: any) => {
    firstCards(values)
      .then(data => {
        closeModal()
        navigate('/active-card', { state: data.data })
      })
      .catch(err => {
        setError('pinCode', { message: err.response.data.description })
      })
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Сканирование карты
        </h2>
        <FormControl fullWidth className={'mui-input'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputRa
              name={'number'}
              label={'Отсканируйте карту сканером'}
              control={control}
              errors={errors}
              autoFocus={true}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  setDisabled(true)
                  document.getElementById('pinCode')?.focus()
                }
              }}
              placeholder={'Введите номер карты'}
              // disabled={disabled}
            />
            <TextInputRa
              name={'pinCode'}
              label={'Секретный код карты'}
              control={control}
              errors={errors}
              placeholder={'Введите код карты'}
              id={'pinCode'}
            />
            <div className={'station-form__block-buttons'}>
              <Button
                type={'submit'}
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Далее
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={closeModal}
              >
                Отмена
              </Button>
            </div>
          </form>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormCard
