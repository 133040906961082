import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { oneClient, updateClients } from '../../request/clients'
import { Client } from '../../types/clients'
import Form from './form'

const EditClient = () => {
  let { client } = useParams()
  const [state, setState] = React.useState<Client | null>(null)

  const navigate = useNavigate()

  React.useEffect(() => {
    oneClient({ id: client as any }).then(data => setState(data.data))
  }, [])

  if (!state) {
    return <div>-</div>
  }
  return (
    <Form
      title={'Редактировать клиента'}
      defaultValues={
        {
          ...state,
          subjectForm: Number(state.subjectForm) === 2 ? true : (false as any),
          discountType: state.discountType === 2 ? true : false,
        } as Client
      }
      onSubmit={(props: any, setErrors: any) =>
        updateClients(props)
          .then(() => {
            navigate(`/clients/${state.id ?? ''}`, {
              state: { id: state.id },
            })
          })
          .catch(error => setErrors(error?.response?.data?.description))
      }
    />
  )
}
export default EditClient
