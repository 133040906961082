import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { style } from '../../styles/style'
import { schemaFuel } from './schema'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { useRequest } from '../../request/use-request'
import { addedBalance } from '../../request/clients'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues?: {
    uuid?: string
  }
  onSuccess?: () => void
}

function formatSum(sum: any) {
  return Math.ceil(sum * 100) / 100
}

const FormAddedFuel = ({
  open,
  handleClose,
  defaultValues,
  onSuccess,
}: Props) => {
  const { data: fuels }: { data: any } = useRequest({
    url: '/fuels',
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schemaFuel),
    defaultValues: {
      ...defaultValues,
      purchasePrice: null,
      amount: null,
      count: null,
    },
  })

  function closeModal() {
    handleClose()
    reset({
      purchasePrice: null,
      amount: null,
    })
  }
  const onSubmit = (values: any) => {
    let params = values
    if (values.purchasePrice) {
      params = { ...params, purchasePrice: Number(values.purchasePrice) }
    }
    addedBalance({
      ...params,
      uuid: defaultValues?.uuid,
      fuelID: Number(values.fuelID),
    }).then(() => {
      closeModal()
      onSuccess && onSuccess()
    })
  }
  if (!fuels) {
    return <div>Loading</div>
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Пополнить карту
        </h2>
        <FormControl fullWidth className={'mui-input'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputSelectRa
              name={'fuelID'}
              control={control}
              label={'Вид топлива'}
              errors={errors}
              options={
                fuels?.items?.map((item: any) => ({
                  id: item.id,
                  name: item.title,
                })) || []
              }
            />
            <TextInputRa
              name={'purchasePrice'}
              label={'Цена за литр, если литровая карта'}
              control={control}
              errors={errors}
              type={'number'}
              placeholder={'Укажите цену'}
              onChange={value => {
                if (watch('count') && value.target.value) {
                  setValue(
                    'amount',
                    //@ts-ignore
                    formatSum(
                      //@ts-ignore
                      Number(watch('count')) * Number(value.target.value)
                    )
                  )
                }
              }}
            />
            <TextInputRa
              name={'amount'}
              label={'Сумма в рублях'}
              control={control}
              errors={errors}
              type={'number'}
              disabled={true}
              placeholder={'Введите сумму'}
              onChange={value => {
                if (watch('purchasePrice') && value.target.value) {
                  setValue(
                    'count',
                    //@ts-ignore
                    formatSum(
                      //@ts-ignore
                      Number(watch('amount')) / Number(watch('purchasePrice'))
                    )
                  )
                }
              }}
            />
            <TextInputRa
              name={'count'}
              label={'Кол-во литров'}
              control={control}
              errors={errors}
              type={'number'}
              placeholder={'Введите литры'}
              onChange={value => {
                if (watch('purchasePrice') && value.target.value) {
                  setValue(
                    'amount',
                    //@ts-ignore
                    formatSum(
                      Number(watch('count')) * Number(watch('purchasePrice'))
                    )
                  )
                }
                if (!!!value.target.value) {
                  //@ts-ignore
                  setValue('amount', formatSum(0))
                }
              }}
            />

            <div className={'station-form__block-buttons'}>
              <Button
                type={'submit'}
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Добавить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={closeModal}
              >
                Отмена
              </Button>
            </div>
          </form>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormAddedFuel
