import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

export const CheckboxInputRa = (props: {
  name: string
  control: any
  label: string
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          label={''}
          control={
            <div className={'miu-checkbox'}>
              <Checkbox
                className={'miu-checkbox__check'}
                color={'primary'}
                checked={field.value}
                {...field}
              />
              <div className={'miu-checkbox__label medium-text'}>
                {props.label}
              </div>
            </div>
          }
        ></FormControlLabel>
      )}
    />
  )
}
