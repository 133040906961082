import React from 'react'
import { useNavigate } from 'react-router-dom'
import { addedFuelsInPetrolStation, addedPetrolStations } from '../../request'
import Form from './form'

const CreateStation = () => {
  const navigate = useNavigate()
  return (
    <Form
      title={'Добавить АЗС'}
      defaultValues={{
        title: null,
        address: null,
        phone: null,
        fuelIDs: [],
      }}
      onSubmit={(props: any, setErrors: any) => {
        addedPetrolStations(props)
          .then((data: any) => {
            addedFuelsInPetrolStation({
              id: data.data.id,
              fuelIDs: props.fuelIDs,
            }).then(() =>
              navigate('/stations', { state: { id: data.data.id } })
            )
          })
          .catch(error => setErrors(error?.response?.data?.description))
      }}
    />
  )
}
export default CreateStation
