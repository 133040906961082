import { getLiterAmount } from '../../formatters/liter-amount'
import { Result } from '../types/type'

export function getAmount(item: Result) {
  return getLiterAmount(
    -1 *
      item.transactions.reduce((sum, elem) => {
        sum = sum + Number(elem.amount)
        return sum
      }, 0)
  )
}
export function getAmountLiter(item: Result) {
  return getLiterAmount(
    -1 *
      item.transactions.reduce((sum, elem) => {
        sum = sum + Number(elem.literAmount)
        return sum
      }, 0)
  )
}

export function getTotalAmount(record: Result[]) {
  return getLiterAmount(
    -1 *
      record.reduce((sum, card) => {
        sum =
          sum +
          Number(
            card.transactions.reduce((sumCard, item) => {
              sumCard = sumCard + Number(item.amount)
              return sumCard
            }, 0)
          )

        return sum
      }, 0)
  )
}
export function getTotalAmountLiter(record: Result[]) {
  return getLiterAmount(
    -1 *
      record.reduce((sum, card) => {
        sum =
          sum +
          Number(
            card.transactions.reduce((sumCard, item) => {
              sumCard = sumCard + Number(item.literAmount)
              return sumCard
            }, 0)
          )

        return sum
      }, 0)
  )
}
export function getCountTransaction(record: Result[]) {
  return record.reduce((sum, card) => {
    sum = sum + Number(card.transactions.length)
    return sum
  }, 0)
}
