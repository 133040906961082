import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { style } from '../styles/style'

type Props = {
  handleClose: () => void
  handleDelete: () => void
  open: boolean
}

export const ModalDelete = (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Подтвердить удаление
        </h2>
        <div className={'medium-text'}>
          Нажмите &laquo;Подтвердить&raquo; если Вы действительно хотите
          удалить?
        </div>
        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={props.handleDelete}
          >
            Подтвердить
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={props.handleClose}
          >
            Отмена
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
