import { format } from 'date-fns'
import printJS from 'print-js'
import { reportFuel } from '../request/fuels'
import { getUserNameOrCompany } from './getUserNameOrCompany'

export function getPrintReport(workingShiftID: number) {
  return reportFuel({ workingShiftID: workingShiftID }).then(reports => {
    let str = []

    let detail = []

    for (let i = 0; reports?.data?.customerFuelConsumptions?.length > i; i++) {
      const elem = reports?.data?.customerFuelConsumptions[i]
      const name = getUserNameOrCompany(elem.customer)
      let params = []
      for (let j = 0; elem.fuelConsumptions?.length > j; j++) {
        params.push(
          `<div style='margin-right:10px;font-size:14px; font-weight:500; color:var(--color-text); padding-right:5px; border-right:1px solid gray;'>${
            elem?.fuelConsumptions?.[j]?.fuel
          } / ${Math.abs(elem?.fuelConsumptions?.[j]?.literAmountSum)} л.</div>`
        )
      }
      detail.push(
        `<div><div style="display:flex; margin-bottom:10px"><div style='margin-right:30px;min-width:250px'>${
          i + 1
        }) ${name}</div><div style='display:flex'>${params.join(
          ''
        )}</div></div></div`
      )
    }

    for (let i = 0; reports?.data?.totalFuelConsumptions?.length > i; i++) {
      str.push(
        `<div>${reports.data?.totalFuelConsumptions?.[i]?.fuel} = ${Math.abs(
          reports.data?.totalFuelConsumptions?.[i]?.literAmountSum
        )} л. </div>`
      )
    }
    printJS({
      printable: `<div id="print-content" className={'print-content'}>
            <div>${format(new Date(), 'dd.MM.yyyy в HH:mm')}</div>
            <hr></hr>
            <div>Номер смены: ${workingShiftID}</div>
            <br></br>
            <div>Детализация:</div>
            <div >${detail.join('')}</div>
            <br></br>
            
            <div>Итого литров заправлено:</div>
            ${str.join('')}
          </div>`,
      type: 'raw-html',
    })
  })
}
