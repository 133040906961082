import React from 'react'
import { Balance } from '../../../types/balance'
import { Card } from '../../../types/card'
import { FuelCardClient } from './client-card'
import { Filter } from './Filter'

type Props = {
  cards: Card[]
  updateStatus: (uuid: string, params: string) => void
  setIsDelete: ({ isOpen, id }: { isOpen: boolean; id: string | null }) => void
  setOpenModalChangePinCode: ({
    status,
    uuid,
  }: {
    status: boolean
    uuid: string | null
  }) => void
}

export const ComponentListFuelCardShowClient = (props: Props) => {
  const { cards, updateStatus, setIsDelete, setOpenModalChangePinCode } = props

  return (
    <div>
      <Filter />
      <div className={'cards-client'}>
        {cards?.map((card: Card) => (
          <FuelCardClient
            key={card.id}
            numberCard={card.number}
            statusCard={card.status}
            updateStatus={updateStatus}
            deleteCard={(uuid: string) =>
              setIsDelete({ isOpen: true, id: uuid as any })
            }
            openModal={uuid =>
              setOpenModalChangePinCode({
                status: true,
                uuid: uuid as any,
              })
            }
            title={card.title ?? ''}
            uuid={card.uuid}
            balances={card.balances as Balance[]}
          />
        ))}
      </div>
    </div>
  )
}
