import { Button, FormControl } from '@mui/material'
import { endOfMonth, startOfMonth } from 'date-fns'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { formatMoney } from '../../formatters/formatMoney'
import { reportsFuels } from '../../request/fuels'
import { renderToString } from 'react-dom/server'
import { Card } from '../home'
import { getPrintReport } from './getPrintReport'
import { getPrintClientTransactions } from '../../print-reports/print-client-transactions'

const FuelList = ({ reports }: { reports: any }) => {
  return (
    <div
      id="report-print-form"
      style={{ display: 'flex', marginTop: 30, flexWrap: 'wrap' }}
    >
      {reports?.customerFuelConsumptions?.map((report: any) => {
        return report?.fuelConsumptions.map((fuel: any, key: number) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10,
            }}
            key={key}
          >
            <div
              style={{
                border: '1px solid gray',
                textAlign: 'center',
                padding: '10px 5px',
              }}
            >
              {fuel?.fuel}
            </div>
            <div style={{ display: 'flex' }}>
              {fuel?.detailFuelConsumptions.map((item: any, key: number) => (
                <div style={{ width: '100%' }} key={key}>
                  <div
                    style={{
                      border: '1px solid gray',
                      textAlign: 'center',
                      minWidth: 120,
                      padding: '5px 0',
                    }}
                  >
                    {item?.literPrice} ₽
                  </div>
                  <div
                    style={{
                      border: '1px solid gray',
                      textAlign: 'center',
                      padding: '5px 0',
                    }}
                  >
                    {item?.literAmountSum * -1} л.
                  </div>
                  <div
                    style={{
                      border: '1px solid gray',
                      textAlign: 'center',
                      padding: '5px 0',
                    }}
                  >
                    {formatMoney(item?.amountSum * -1)} ₽
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      })}
    </div>
  )
}

const defaultValues = {
  dateTo: endOfMonth(new Date()),
  dateFrom: startOfMonth(new Date()),
}

const ReportTransactions = ({ client_id }: { client_id: number }) => {
  const [reports, setReports] = React.useState<any>([])
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      customerID: client_id,
    },
  })

  React.useEffect(() => {
    reportsFuels({
      customerID: client_id,
      ...defaultValues,
    }).then(data => setReports(data?.data))
  }, [])

  const resetFilter = () => {
    reset({ dateFrom: '' as any, dateTo: '' as any, customerID: client_id })
    reportsFuels({ customerID: client_id }).then(data => setReports(data?.data))
  }

  const onSubmit = (values: any) => {
    reportsFuels({ ...values }).then(data => setReports(data?.data))
  }

  return (
    <div>
      <FormControl fullWidth className={'mui-input'}>
        <div className={'na-filter__fields'}>
          <div>
            <TextInputDateRa
              name={'dateFrom'}
              control={control}
              label={'Дата от'}
              errors={errors}
            />
          </div>
          <div>
            <TextInputDateRa
              name={'dateTo'}
              control={control}
              label={'Дата до'}
              errors={errors}
            />
          </div>
        </div>

        <div className={'station-form__block-buttons'}>
          <Button
            variant="contained"
            className={'btn-default btn-blue bnt-icon-ok'}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
          <Button
            variant="contained"
            className={'btn-default btn-gray btn-icon-cancel'}
            onClick={resetFilter}
          >
            Очистить
          </Button>
        </div>
      </FormControl>
      <div style={{ display: 'flex', marginTop: 30, flexWrap: 'wrap' }}>
        {reports &&
          //@ts-ignore
          reports?.totalFuelConsumptions?.map((fuel: any) => (
            <div style={{ marginRight: 10, marginBottom: 10 }} key={fuel?.id}>
              <Card
                value={`${Math.abs(fuel?.literAmountSum)} л. / ${formatMoney(
                  fuel.amountSum * -1
                )} ₽`}
                title={fuel?.fuel}
              />
            </div>
          ))}
      </div>
      <div style={{ width: 200, marginTop: 20 }}>
        <Button
          variant="contained"
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={() =>
            getPrintReport({
              filter: getValues(),
              print: renderToString(<FuelList reports={reports} />),
            })
          }
        >
          Печать
        </Button>
      </div>
      <FuelList reports={reports} />
    </div>
  )
}
export default ReportTransactions
