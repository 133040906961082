import { format } from 'date-fns'
import printJS from 'print-js'
import React from 'react'
import { renderToString } from 'react-dom/server'
import { getLiterAmount } from '../formatters/liter-amount'
import {
  isReportTransaction,
  Transactions,
} from '../formatters/type-transaction'
import { getShortNameStation } from '../helpers/getStation'
import {
  getAmount,
  getAmountLiter,
  getCountTransaction,
  getTotalAmount,
  getTotalAmountLiter,
} from './helperts/math'
import { Result, Transaction } from './types/type'

export function getPrintClientTransactions({
  filters,
  record,
  customer,
}: {
  filters?: any
  record?: any
  customer?: string
}) {
  const result: any = Object.values(
    (record?.items || []).reduce((acc: any, cur: any) => {
      if (isReportTransaction(cur.operationType)) {
        //@ts-ignore
        acc[cur.card.number] = acc[cur.card.number] || {
          card: cur.card.number,
          //@ts-ignore
          title_card: cur.card?.title || '',
          transactions: [],
        }
        //@ts-ignore
        acc[cur.card.number].transactions?.push({
          id: cur.id,
          amount: cur.amount,
          literAmount: getLiterAmount(cur.literAmount),
          literPrice: cur.literPrice,
          date: format(new Date(cur.createdAt), 'dd.MM.yyyy в HH:mm'),
          station: getShortNameStation(cur.petrolStation as any),
          type_operation: Transactions(cur.operationType),
          fuel: cur.fuel.title,
        })
        // На сумму: 2296.59 ₽ Литров: 10.34 л.
        return acc
      } else {
        return acc
      }
    }, {})
  )

  console.log('hello ')
  return printJS({
    printable: `${renderToString(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600',
            fontSize: 24,
          }}
        >
          <b> Протокол транзакций по топливным картам</b>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '500',
            fontSize: 20,
          }}
        >
          c{' '}
          {filters?.dateFrom
            ? format(new Date(filters?.dateFrom), 'dd.MM.yyyy HH:mm')
            : ' - '}{' '}
          по{' '}
          {filters.dateTo &&
            format(new Date(filters?.dateTo), 'dd.MM.yyyy HH:mm')}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600',
            fontSize: 20,
          }}
        >
          для клиента
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '600',
            fontSize: 20,
            marginBottom: 32,
          }}
        >
          {customer}
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div
            style={{ display: 'flex', fontWeight: 'bold', marginBottom: 16 }}
          >
            <div style={{ width: 50, fontWeight: 'bold', marginLeft: 8 }}>
              №
            </div>
            <div style={{ fontWeight: 'bold', width: 150 }}>Дата и Время</div>
            <div style={{ width: 80, fontWeight: 'bold' }}>Операция</div>
            <div style={{ width: 180, fontWeight: 'bold' }}>Адрес</div>
            <div style={{ width: 100, fontWeight: 'bold' }}>Топливо</div>
            <div style={{ flex: 1, fontWeight: 'bold' }}>Сумма</div>
            <div style={{ flex: 1, fontWeight: 'bold' }}>Литры</div>
            <div style={{ flex: 1, fontWeight: 'bold' }}>Цена</div>
          </div>
          <div>
            {result?.map((item: Result) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #2F4F4F',
                  paddingBottom: 10,
                }}
                key={item?.card}
              >
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    borderBottom: '1px solid #D3D3D3',
                  }}
                >
                  <div style={{ marginRight: 32 }}>Карта : </div>
                  <div style={{ marginRight: 32 }}>{item.card || 'Пусто'} </div>
                  <div>{item.title_card}</div>
                </div>
                <div>
                  {item.transactions?.map((transaction: Transaction) => (
                    <div style={{ display: 'flex' }} key={transaction.id}>
                      <div style={{ width: 55, fontSize: 12, marginLeft: 4 }}>
                        {transaction.id}
                      </div>
                      <div style={{ width: 150, fontSize: 12 }}>
                        {transaction.date}
                      </div>
                      <div style={{ width: 80, fontSize: 12 }}>
                        {transaction.type_operation}
                      </div>
                      <div style={{ width: 180, fontSize: 12 }}>
                        {transaction.station}
                      </div>
                      <div style={{ width: 100, fontSize: 12 }}>
                        {transaction.fuel}
                      </div>
                      <div style={{ flex: 1, fontSize: 12 }}>
                        {getLiterAmount(transaction.amount)} ₽
                      </div>
                      <div style={{ flex: 1, fontSize: 12 }}>
                        {getLiterAmount(transaction.literAmount)} л.
                      </div>
                      <div style={{ flex: 1, fontSize: 12 }}>
                        {transaction.literPrice} ₽
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 'bold',
                    justifyContent: 'end',
                    marginTop: 4,
                  }}
                >
                  <div style={{ marginRight: 32 }}>
                    Транзакций: {item.transactions?.length} шт
                  </div>
                  <div style={{ marginRight: 32 }}>
                    На сумму: {getAmount(item)} ₽
                  </div>
                  <div style={{ marginRight: 32 }}>
                    Литров: {getAmountLiter(item)} л.{' '}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              fontWeight: 'bold',
              justifyContent: 'end',
              marginTop: 10,
            }}
          >
            <div style={{ marginRight: 32, fontSize: 18 }}>Общий итог</div>
            <div style={{ marginRight: 32 }}>
              Транзакций: {getCountTransaction(result)} шт
            </div>
            <div style={{ marginRight: 32 }}>
              На сумму: {getTotalAmount(result)} ₽
            </div>
            <div style={{ marginRight: 32 }}>
              Литров: {getTotalAmountLiter(result)} л.
            </div>
          </div>
        </div>
      </div>
    )}`,
    type: 'raw-html',
  })
}
