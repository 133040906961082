import * as yup from 'yup'

export const schemaCard = yup
  .object({
    pinCode: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
    number: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
