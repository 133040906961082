import { format } from 'date-fns'
import printJS from 'print-js'
import React from 'react'
import { formatNumber } from '../../formatters/format'
import { Transactions } from '../../formatters/type-transaction'
import { getStation } from '../../helpers/getStation'
import { getUserName } from '../../helpers/getUserName'
import { getUserNameOrCompany } from '../../helpers/getUserNameOrCompany'
import { searchTransactions } from '../../request/transaction'

export function getPrintTransactions(props: { filters: any; id: number }) {
  const onSubmit = (values: any) => {
    let params = new URLSearchParams('')

    for (let i = 0; i < Object.keys(values).length; i++) {
      Object.entries(values).map((value, key) => {
        if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
          //@ts-ignore
          params.set(value[0], new Date(value[1]).toJSON())
        } else if (value[1]) {
          params.set(value[0], value[1] as string)
        }
      })
    }
    return params.toString()
  }
  let params = onSubmit(props.filters)
  searchTransactions(`?${params}&countPerPage=${999}&page=${0}`).then(data => {
    printJS({
      printable:
        data.data?.items?.map((item: any) => ({
          id: item.id,
          responsible: getUserNameOrCompany({ ...item.card.customer }),
          balance: 0,
          type_transaction: Transactions(item.operationType),
          sum: item.amount,
          literAmount: item.literAmount,
          literPrice: item.literPrice,
          time_and_date: format(new Date(item.createdAt), 'dd.MM.yyyy в HH:mm'),
          fuel: item?.fuel?.title || 'Удален',
          station: getStation(item.petrolStation),
          card_number: formatNumber(item?.card?.number, ' '),
        })) || [],
      properties: [
        { field: 'id', displayName: 'Номер', style: { color: 'red' } },
        { field: 'card_number', displayName: 'Номер карты' },
        { field: 'responsible', displayName: 'Клиент' },
        { field: 'type_transaction', displayName: 'Тип операции' },
        { field: 'time_and_date', displayName: 'Дата и Время' },
        { field: 'station', displayName: 'Станция' },
        { field: 'sum', displayName: 'Сумма' },
        { field: 'literAmount', displayName: 'Кол-во литров' },
        { field: 'literPrice', displayName: 'Цена литра' },
      ],
      type: 'json',
      header: `<div>
      <div style="display:flex; flex-direction:column;">
          <div>Дата от: ${
            props.filters.dateFrom
              ? format(new Date(props.filters.dateFrom), 'dd.MM.yyyy в HH:mm')
              : '-'
          }</div>
          <div>Дата до: ${
            props.filters.dateTo
              ? format(new Date(props.filters.dateTo), 'dd.MM.yyyy в HH:mm')
              : '-'
          }</div>
          <div>Всего: ${data.data.totalCount}</div>
      </div>
            </div>`,
    })
  })
}
