import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GoBack } from '../../components/go-back'
import { TitleComponent } from '../../components/title'
import { useRequest } from '../../request/use-request'
import { ModalChangePassword } from './modal-change-password'
import { ClientTransactionTable } from './table'
import { ModalChangePinCode } from './modal-change-pincode'
import { deleteCard, updateStatusCard } from '../../request/cards'
import ReportTransactions from './report-transaction'
import { ModalDelete } from '../../components/modal-delete'
import { getUserNameOrCompany } from '../../helpers/getUserNameOrCompany'
import { ComponentListFuelCardShowClient } from './components-show'
import { ClientInfo } from './components-show/client-info'
import { ToolbarButtonClientInfo } from './components-show/client-info/toolbar'

import './style.scss'

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CardElem = (props: { value: string; title: string }) => (
  <div className={'card-petrol'}>
    <div className={'card-petrol__title'}>{props.title}:</div>
    <div className={'card-petrol__value'}>{props.value}</div>
  </div>
)

function getDiscountType(data: any) {
  if (data.discountType === 2) {
    return 'Скидка в рублях с литра'
  } else if (data.discountType === 1) {
    return 'Скидка в процентах с литра'
  } else {
    return '-'
  }
}

const ShowClient = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state, search: searchLocation } = useLocation()
  const { data, loader }: { data: any; loader: boolean } = useRequest({
    url: `/customers/${id}`,
  })
  const [openModalChangePassword, setOpenModalChangePassword] =
    React.useState(false)

  const [openModalChangePinCode, setOpenModalChangePinCode] = React.useState<{
    status: boolean
    uuid: string | null
  }>({
    status: false,
    uuid: null,
  })

  const {
    data: cards,
    search,
  }: { data: any; search: (params: string) => void } = useRequest({
    url: searchLocation
      ? `/cards${searchLocation}&customerID=${id}`
      : `/cards?customerID=${id}`,
  })

  const [value, setValue] = React.useState(state?.step || 0)

  const [isDelete, setIsDelete] = React.useState<{
    isOpen: boolean
    id: string | null
  }>({ isOpen: false, id: null })

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function searchCards() {
    if (searchLocation) {
      search(`/cards${searchLocation}&customerID=${id}`)
    } else {
      search(`/cards?customerID=${id}`)
    }
  }

  const updateStatus = (uuid: any, status: string) => {
    updateStatusCard({ number: uuid, status: status }).then(() => searchCards())
  }

  const deleteCardClient = ({ uuid }: { uuid: string }) => {
    deleteCard({ uuid }).then(() => {
      setIsDelete({ isOpen: false, id: null })
      searchCards()
    })
  }

  useEffect(() => {
    searchCards()
  }, [searchLocation, state?.updateList])

  if (loader || !data) {
    return <CircularProgress />
  }

  return (
    <div className={'client-show container'}>
      <div>
        <ModalChangePassword
          open={openModalChangePassword}
          handleClose={() => setOpenModalChangePassword(false)}
          client_id={id as any}
        />
        <ModalDelete
          open={isDelete.isOpen}
          handleClose={() => setIsDelete({ isOpen: false, id: null })}
          handleDelete={() => deleteCardClient({ uuid: isDelete.id as any })}
        />
        <ModalChangePinCode
          open={openModalChangePinCode.status}
          handleClose={() =>
            setOpenModalChangePinCode({ status: false, uuid: null })
          }
          uuid={openModalChangePinCode.uuid as any}
        />
      </div>
      <GoBack to={'/clients'} />
      <div className={'client-show__block-title'}>
        <TitleComponent title={'Подробная информация'} />
      </div>
      <div className={'client-show__block-body'}>
        <div className={'client-show__block-body__info'}>
          <ClientInfo client={data as any} />
          <ToolbarButtonClientInfo
            clientId={id as any}
            setOpenModalChangePassword={setOpenModalChangePassword}
          />
        </div>
        <hr />
        <div className={'client-show__tabs'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Карты" {...a11yProps(0)} />
              <Tab label="Транзакции" {...a11yProps(1)} />
              <Tab label="Детализация" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ComponentListFuelCardShowClient
              cards={cards?.items ?? []}
              updateStatus={updateStatus}
              setIsDelete={setIsDelete}
              setOpenModalChangePinCode={setOpenModalChangePinCode}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ClientTransactionTable
              id={id}
              customer={getUserNameOrCompany(data)}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReportTransactions client_id={id as any} />
          </TabPanel>
        </div>
      </div>
    </div>
  )
}
export default ShowClient
