import * as yup from 'yup'

const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/

export const schema = yup
  .object({
    title: yup
      .string()
      .trim()
      .required('Обязательно к заполнению')
      .typeError('Обязательно к заполнению'),
  })
  .required()
