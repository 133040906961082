import { Button } from '@mui/material'
import React from 'react'
import { TitleComponent } from '../../components/title'
import { FuelCreate, permission } from '../../permission'
import { listFuels } from '../../request/fuels'
import ListFuels from './list'
import './style.scss'

const FuelsPage = () => {
  const [open, setOpen] = React.useState(false)
  const [list, setList] = React.useState([])
  const handleClose = () => {
    setOpen(false)
    getList()
  }
  const handleOpen = () => setOpen(true)

  function getList() {
    listFuels().then(data => setList(data.data.items || []))
  }

  React.useEffect(() => {
    getList()
  }, [])

  return (
    <div className={'stations container'}>
      <div className="stations__toolbar">
        <div>
          <TitleComponent title={'Список видов топлива'} />
        </div>

        <div>
          {permission(FuelCreate) && (
            <Button className={' btn-default btn-gray'} onClick={handleOpen}>
              Добавить вид топлива
            </Button>
          )}
        </div>
      </div>
      <ListFuels
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        list={list}
        getList={getList}
      />
    </div>
  )
}

export default FuelsPage
