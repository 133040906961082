import React from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  addedFuelsInPetrolStation,
  fuelsPetrolStation,
  onePetrolStation,
  updatePetrolStation,
} from '../../request'
import Form from './form'

const EditStation = () => {
  let { station } = useParams()
  const [state, setState] = React.useState(null)

  const navigate = useNavigate()
  React.useEffect(() => {
    onePetrolStation({ id: station as any }).then(data =>
      fuelsPetrolStation({ id: station as any }).then(fuels => {
        setState({
          ...data.data,
          fuelIDs: fuels.data?.map((fuel: any) => fuel?.fuel?.id) || [],
        })
      })
    )
  }, [])
  if (!state) {
    return <div>-</div>
  }
  return (
    <Form
      title={'Редактировать АЗС'}
      onSubmit={(props: any, setErrors) =>
        updatePetrolStation(props)
          .then(data => {
            addedFuelsInPetrolStation({
              id: station as any,
              fuelIDs: props.fuelIDs,
            }).then(() =>
              navigate('/stations', { state: { id: data.data.id } })
            )
          })
          .catch(error => setErrors(error?.response?.data?.description))
      }
      defaultValues={state as any}
    />
  )
}
export default EditStation
