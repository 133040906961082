import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { updateFuelsInPetrolStation } from '../../request'
import { style } from '../../styles/style'
import { customResolver } from './custom-resolver'

type Props = {
  id: number
  handleClose: () => void
  open: boolean
  data: { id: number; name: string; price: string }[]
}

export const ModalUpdateFuels = (props: Props) => {
  const { data } = props
  React.useEffect(() => {
    for (let fuel = 0; fuel < data.length; fuel++) {
      setValue(String(data[fuel].id), data[fuel].price)
    }
  }, [data])

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: customResolver,
    mode: 'onChange',
  })

  const onSubmit = (values: any) => {
    updateFuelsInPetrolStation({
      id: props.id,
      data: Object.entries(values).map((key, value) => ({
        fuelID: Number(key[0]),
        price: Number(key[1]),
      })),
    }).then(() => props.handleClose())
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Цена топлива на стелле
        </h2>
        <FormControl
          style={{ overflow: 'auto', maxHeight: 500 }}
          fullWidth
          className={'mui-input'}
        >
          {props?.data?.map(field => (
            <TextInputRa
              key={field.id}
              name={String(field.id)}
              label={field.name}
              control={control}
              errors={errors}
              placeholder={'Укажите актуальную цену'}
            />
          ))}
          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={props.handleClose}
            >
              Отмена
            </Button>
          </div>
        </FormControl>
      </Box>
    </Modal>
  )
}
