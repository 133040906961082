import axios from 'axios'
import env from '../env'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const listTransaction = (page: number, perPage: number) => {
  return baseURL().get(`card-transaction-histories?page=1&countPerPage=20`)
}

export const searchTransactions = (filter: any) => {
  return baseURL().get(`card-transaction-histories${filter}`)
}

export const searchFilterRequest = (props: {
  resource: string
  filter: any
}) => {
  return baseURL().get(`${props.resource}${props.filter}`)
}

export const refundsCardTransaction = (props: {
  literAmount: number
  id: number
}) => {
  return baseURL().patch(`card-transaction-histories/${props.id}`, {
    literAmount: props.literAmount,
  })
}
