import { Button } from '@mui/material'
import React from 'react'
import { TitleComponent } from '../../../components/title'
import { Card } from '../../../types/card'
import { TableListCards } from './list-card/TableListCards'

type Props = {
  cards: Card[] | []
  handleOpenCorrect: ({ uuid }: { uuid: string }) => void
  handleOpenFuel: (uuid: string) => void
  handleOpen: () => void
}

export const ComponentListCard = (props: Props) => {
  const { cards, handleOpenCorrect, handleOpenFuel, handleOpen } = props

  return (
    <div className={'client-form'}>
      <div className={'client-form__list-cards'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TitleComponent title={'Список привязанных карт'} />
          <div style={{ marginLeft: 'auto', width: 200 }}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleOpen}
            >
              Добавить карту
            </Button>
          </div>
        </div>
        <TableListCards
          cards={cards ?? []}
          handleOpenCorrect={handleOpenCorrect}
          handleOpenFuel={handleOpenFuel}
        />
      </div>
    </div>
  )
}
