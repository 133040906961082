import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router'
import { ModalDelete } from '../../components/modal-delete'
import { deletePetrolStation, petrolStations } from '../../request'
import {
  permission,
  PetrolStationDelete,
  PetrolStationUpdate,
} from '../../permission'

interface Column {
  id: 'address' | 'phone' | 'title' | 'id' | 'method'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const ListStations = (props: any) => {
  const navigate = useNavigate()

  const [isDelete, setIsDelete] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState<Number | null>(null)

  const handleOpen = () => setIsDelete(true)

  const checkIdDelete = (id: number) => {
    handleOpen()
    setIdDelete(id)
  }
  const handleDelete = () => {
    deletePetrolStation({ id: idDelete as any }).then(() => {
      handleClose()
      petrolStations().then(data => props.setList(data.data?.items))
    })
  }

  const handleClose = () => setIsDelete(false)

  const columns: readonly Column[] = [
    { id: 'id', label: '№ станции' },
    { id: 'title', label: 'Наименование', minWidth: 250 },
    { id: 'address', label: 'Адрес', minWidth: 50 },
    { id: 'phone', label: 'Телефон для связи', minWidth: 250 },
    {
      id: 'method',
      label: '',
      minWidth: 120,
      align: 'right',
      //@ts-ignore
      format: value => (
        <div className={'icons-delete-edit'}>
          {permission(PetrolStationUpdate) && (
            <img
              onClick={() => navigate(`edit/${value}`)}
              src={'/assets/icons/edit.svg'}
            />
          )}
          {/* {permission(PetrolStationDelete) && (
            <img
              onClick={() => checkIdDelete(value)}
              src={'/assets/icons/delete.svg'}
            />
          )} */}
        </div>
      ),
    },
  ]

  return (
    <>
      <ModalDelete
        handleClose={handleClose}
        handleDelete={handleDelete}
        open={isDelete}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(props.list || []).map((row: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value =
                        row[column.id !== 'method' ? column.id : 'id']
                      return (
                        <TableCell
                          onClick={() =>
                            column.id !== 'method' &&
                            navigate(`/stations/${row.id}`)
                          }
                          key={column.id}
                          align={column.align}
                        >
                          {column.format ? column.format(value as any) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default ListStations
