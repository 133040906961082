import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, Modal } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { addedCards } from '../../request/cards'
import { style } from '../../styles/style'
import { schemaCard } from './schema'
import printJS from 'print-js'
import { TextInputNumberCardRa } from '../../components/inputs/text-field-number-card'
import { clearNumberCard } from '../../formatters/clear-number-card'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues?: {
    customerID: number
    number?: string
  }
}

const FormCard = ({ open, handleClose, defaultValues }: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCard),
    defaultValues: defaultValues,
  })

  function closeModal() {
    handleClose()
    reset()
  }
  const onSubmit = (values: any) => {
    addedCards({
      customerID: Number(values.customerID),
      number: clearNumberCard(values.number),
    })
      .then(data => {
        closeModal()
        printJS({
          printable: `<div id="print-content" className={'print-content'}>
          <img src="/assets/logo.png" style=" width: 200px" />
          <div style="fontSize:20px">Номер карты - ${data.data.number}</div>
          <div style="fontSize:20px">Секретный код - ${data.data.pinCode}</div>
          <hr></hr>
        </div>`,
          type: 'raw-html',
        })
      })
      .catch(error =>
        setError('number', { message: error?.response?.data?.description })
      )
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Привязать карту
        </h2>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          fullWidth
          className={'mui-input'}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputNumberCardRa
              name={'number'}
              label={'Номер карты'}
              control={control}
              errors={errors}
              autoFocus={true}
            />
            <div className={'station-form__block-buttons'}>
              <Button
                type="submit"
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Добавить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={closeModal}
              >
                Отмена
              </Button>
            </div>
          </form>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormCard
