import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Button, FormControl } from '@mui/material'
import { useRequest } from '../../request/use-request'
import { getUserName } from '../../helpers/getUserName'
import { Transactions } from '../../formatters/type-transaction'
import { getStation } from '../../helpers/getStation'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { formatMoney } from '../../formatters/formatMoney'
import { formatNumber } from '../../formatters/format'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import { getPrintCheck } from '../../helpers/getPrintCheck'
import { getUserNameOrCompany } from '../../helpers/getUserNameOrCompany'
import { getActiveWorkingShiftID } from '../../helpers/getActiveWorkingShiftID'
import { searchTransactions } from '../../request/transaction'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getAdminRole } from '../../helpers/getAdminRole'
import { getEmployeeRole } from '../../helpers/getEmployeeRole'
import {
  getOperationType,
  TypeTransaction,
} from '../../const/type-transactions'

interface Column {
  id:
    | 'id'
    | 'responsible'
    | 'station'
    | 'balance'
    | 'card_number'
    | 'type_transaction'
    | 'sum'
    | 'time_and_date'
    | 'fuel'
    | 'client'
    | 'purchasePrice'
    | 'literSum'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number, data_print?: any) => string
}

const columns: readonly Column[] = [
  { id: 'card_number', label: 'Номер карты' },
  {
    id: 'client',
    label: 'Клиент',
    format: value => getUserNameOrCompany(value),
  },
  { id: 'responsible', label: 'Ответственный' },
  {
    id: 'station',
    label: 'АЗС',
  },
  { id: 'fuel', label: 'Вид топлива' },
  {
    id: 'literSum',
    label: 'Сумма (л.)',
    minWidth: 100,
    format: (value: number) => value + ' л.',
  },
  {
    id: 'sum',
    label: 'Сумма (₽)',
    minWidth: 100,
    format: (value: number) => value + ' ₽',
  },
  {
    id: 'purchasePrice',
    label: 'Цена за литр (₽)',
    minWidth: 100,
    format: (value: number) =>
      //@ts-ignore
      value !== '0' ? value + ' ₽' : '-',
  },
  {
    id: 'type_transaction',
    label: 'Тип транзакции',
    format: value => Transactions(value),
  },
  {
    id: 'time_and_date',
    label: 'Дата и Время',
    minWidth: 120,
    align: 'right',
    //@ts-ignore
    format: (value: string) =>
      format(new Date(value), 'dd.MM.yyyy в HH:mm', { locale: ru }),
  },
  {
    id: 'id',
    label: '',
    minWidth: 50,
    //@ts-ignore
    format: (value, data_print) =>
      (data_print.operationType === 2 || data_print.operationType === 4) && (
        <div
          onClick={() =>
            getPrintCheck({
              station: {
                id: data_print.petrolStation.id,
                title: data_print.petrolStation.title,
                address: data_print.petrolStation.address,
              },
              type: getOperationType(data_print.operationType),
              id_check: data_print?.id,
              number: data_print?.card_number,
              date: format(
                new Date(data_print?.time_and_date),
                'dd.MM.yyyy в HH:mm'
              ),
              operator: getUserName(data_print?.employee),
              write: {
                title: data_print.fuel,
                amount: Math.abs(data_print?.literSum),
              },
              balance: {
                title: data_print?.fuel,
                amount: data_print?.remainderLiterAmount,
              },
            })
          }
          title={'Печать чека'}
          className={'button-print'}
        >
          <LocalPrintshopIcon htmlColor={'white'} />
        </div>
      ),
  },
]

export const StickyHeadTable = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [data, setData] = React.useState<any>({})

  function searchTransaction(filter: string) {
    return searchTransactions(filter).then(result => setData(result.data))
  }

  function getDefaultFilter(value?: string) {
    let params = new URLSearchParams(value || '')
    if (getEmployeeRole()) {
      params.set('operationTypes', JSON.stringify([2, 4]))
      params.set('petrolStationID', getPetrolStation('id') as string)
    }
    params.set('countPerPage', String(rowsPerPage))
    params.set('page', String(page + 1))
    navigate(`/transactions?${params.toString()}`)
  }

  React.useEffect(() => {
    getDefaultFilter()
  }, [])

  const { data: petrolStation }: { data: any } = useRequest({
    url: '/petrol-stations',
  })

  React.useEffect(() => {
    getDefaultFilter(location.search)
  }, [page, rowsPerPage])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    getDefaultFilter()
  }

  React.useEffect(() => {
    if (location?.search) {
      searchTransaction(location?.search)
    }
  }, [location.search])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      petrolStationID: null,
      cardNumber: null,
      customer: '',
      operationType: null,
    },
  })

  const resetFilter = () => {
    reset({
      cardNumber: null,
      petrolStationID: null,
      customer: '',
      operationType: null,
    })
    setPage(0)
    getDefaultFilter()
  }
  const onSubmit = (values: any) => {
    let params = new URLSearchParams(location.search)
    for (let i = 0; i < Object.keys(values).length; i++) {
      Object.entries(values).map((value, key) => {
        if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
          //@ts-ignore
          params.set(value[0], new Date(value[1]).toJSON())
        } else if (value[1]) {
          params.set(value[0], value[1] as string)
        }
      })
    }
    params.set('page', '1')
    setPage(0)
    navigate(`/transactions?${params.toString()}`)
  }

  if (!data) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      <div className={'na-filter'} style={{ marginBottom: 30 }}>
        <FormControl fullWidth className={'mui-input'}>
          <div className={'na-filter__fields'}>
            <div>
              <TextInputRa
                name={'cardNumber'}
                label={'Номер карты'}
                control={control}
                errors={errors}
                placeholder={'Введите номер карты'}
              />
            </div>
            {petrolStation && (
              <div>
                <TextInputSelectRa
                  name={'petrolStationID'}
                  control={control}
                  label={'Станция АЗС'}
                  errors={errors}
                  options={
                    petrolStation?.items?.map((item: any) => ({
                      id: item.id,
                      name: item.title,
                    })) || []
                  }
                />
              </div>
            )}
            <div>
              <TextInputRa
                name={'customer'}
                label={'Клиент'}
                control={control}
                errors={errors}
                placeholder={'Введите фио или компанию'}
              />
            </div>
            <div>
              <TextInputSelectRa
                name={'operationTypes'}
                control={control}
                label={'Тип операции'}
                errors={errors}
                options={TypeTransaction}
              />
            </div>
            <div>
              <TextInputDateRa
                name={'dateFrom'}
                control={control}
                label={'Дата от'}
                errors={errors}
              />
            </div>
            <div>
              <TextInputDateRa
                name={'dateTo'}
                control={control}
                label={'Дата до'}
                errors={errors}
              />
            </div>
          </div>
          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Применить
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={resetFilter}
            >
              Очистить
            </Button>
          </div>
        </FormControl>
      </div>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.items
                  ?.map((item: any) => ({
                    ...item,
                    id: item.id,
                    client: item?.card?.customer || '-',
                    responsible: getUserName({ ...item.employee }),
                    balance: 0,
                    type_transaction: item.operationType,
                    sum: item.amount,
                    literSum: item.literAmount,
                    purchasePrice: item.literPrice,
                    time_and_date: item.createdAt,
                    fuel: item?.fuel?.title || 'Удален',
                    station: getStation(item.petrolStation),
                    card_number: formatNumber(item?.card?.number, ' '),
                  }))
                  .map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(value as any, row)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={data?.totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </React.Fragment>
  )
}
