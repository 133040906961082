import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { const_menu } from './menu'
import LogoutIcon from '@mui/icons-material/Logout'
import './style.scss'
import { AuthContext } from '../../context/authProvider'
import { permission, WorkingShiftOpen } from '../../permission'
import { workingShiftEnd, workingShiftStart } from '../../request/working-shift'
import { userMe } from '../../request'
import { getPrintReport } from '../../helpers/getPrintReport'
import { WorkingShiftContext } from '../../context/workingShiftProvider'
import { useRequest } from '../../request/use-request'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getAdminRole } from '../../helpers/getAdminRole'

const ElemMenu = (props: {
  id: number
  name: string
  link: string
  permission: null | string
  icon: any
}) => {
  if (props.permission) {
    if (permission(props.permission)) {
      return (
        <Link
          className={'header__menu__link medium-text'}
          to={props.link}
          title={props.name}
        >
          <props.icon
            fontSize={'small'}
            sx={{ color: 'var(--color-text)', paddingRight: '5px' }}
          />
          {props.name}
        </Link>
      )
    }
    return <span />
  }
  return (
    <Link
      className={'header__menu__link medium-text'}
      to={props.link}
      title={props.name}
    >
      <props.icon
        fontSize={'small'}
        sx={{ color: 'var(--color-text)', paddingRight: '5px' }}
      />
      {props.name}
    </Link>
  )
}

const Header = () => {
  const { data: fuels, search }: { data: any; search: any } = useRequest({
    url: `petrol-stations/${getPetrolStation('id')}/fuels`,
  })

  function updateFuels() {
    search()
  }

  const navigate = useNavigate()

  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem('user') as any)
  )

  function loadInfo() {
    userMe().then(data => {
      localStorage.setItem('user', JSON.stringify(data.data))
      setData(data.data)
      setIsOpenWorkingShift(data.data.activeWorkingShiftID ? true : false)
    })
  }
  const { setIsAuth }: any = React.useContext(AuthContext)
  const { setIsOpenWorkingShift }: any = React.useContext(WorkingShiftContext)
  function onSubmit() {
    if (data.activeWorkingShiftID) {
      setIsOpenWorkingShift(false)
      return workingShiftEnd()
        .then(() => loadInfo())
        .then(() => getPrintReport(data.activeWorkingShiftID))
        .then(() => localStorage.removeItem('fuels'))
    } else {
      setIsOpenWorkingShift(true)

      return workingShiftStart().then(() => loadInfo())
    }
  }

  return (
    <header id={'header'} className={'header'}>
      <div
        className={'header__panel'}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {!getAdminRole() && (
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            {fuels?.map((fuel: any) => (
              <div
                key={fuel.fuel.id}
                style={{
                  display: 'flex',
                  marginRight: 6,
                  borderRadius: 8,
                  border: '1px solid gray',
                  padding: 5,
                  marginBottom: 6,
                  fontSize: 14,
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    borderRight: '1px solid gray',
                    paddingRight: 6,
                    fontSize: 12,
                  }}
                >
                  {fuel.fuel.title}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    color: 'var(--color-primary)',
                    marginLeft: 6,
                  }}
                >
                  {fuel.price} ₽
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={'header__panel__elem'}>
          {permission(WorkingShiftOpen) && (
            <div
              className={'button-working'}
              style={{
                backgroundColor: data.activeWorkingShiftID
                  ? 'var(--color-primary)'
                  : '#2ADBA8',
                whiteSpace: 'nowrap',
              }}
              onClick={onSubmit}
            >
              {data.activeWorkingShiftID
                ? `Завершить смену №${data.activeWorkingShiftID}`
                : 'Начать смену'}
            </div>
          )}
          <img src={'/assets/profile.svg'} />
          <span className={' medium-text'} style={{ whiteSpace: 'nowrap' }}>
            {data.lastName} {data.firstName.split('')[0]}.
          </span>
          <span
            onClick={() => {
              setIsAuth(false)
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              navigate('/')
            }}
          >
            <LogoutIcon className={'header__panel__elem__exit'} />
          </span>
        </div>
      </div>
      <div className={'header__menu'}>
        {const_menu.map(item => (
          <ElemMenu key={item.id} {...item} />
        ))}
        {!getAdminRole() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              marginLeft: 'auto',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontWeight: '500', marginBottom: 6 }}>
              Техническая поддержка
            </div>
            <div style={{ fontWeight: '500', fontSize: 18 }}>
              +7 929 329-13-00 (Максим)
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
