import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { ru } from 'date-fns/locale'
import { Button, FormControl } from '@mui/material'
import { useRequest } from '../../request/use-request'
import { getUserName } from '../../helpers/getUserName'
import { Transactions } from '../../formatters/type-transaction'
import { getStation } from '../../helpers/getStation'
import { useForm } from 'react-hook-form'
import { TextInputRa } from '../../components/inputs/text-field'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { TextInputDateRa } from '../../components/inputs/date-field'
import { formatNumber } from '../../formatters/format'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import { getPrintCheck } from '../../helpers/getPrintCheck'
import { getPrintTransactions } from './getPrintTransaction'
import { getPrintClientTransactions } from '../../print-reports/print-client-transactions'
import { searchTransactions } from '../../request/transaction'
import { getLiterAmount } from '../../formatters/liter-amount'
import { getOperationType } from '../../const/type-transactions'

interface Column {
  id:
    | 'id'
    | 'responsible'
    | 'station'
    | 'balance'
    | 'card_number'
    | 'type_transaction'
    | 'sum'
    | 'time_and_date'
    | 'fuel'
    | 'literAmount'
    | 'literPrice'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number, data_print?: any) => string
}

const columns: readonly Column[] = [
  { id: 'card_number', label: 'Номер карты' },
  { id: 'responsible', label: 'Ответственный' },
  {
    id: 'station',
    label: 'АЗС',
  },
  { id: 'fuel', label: 'Вид топлива' },
  {
    id: 'literAmount',
    label: 'Сумма (л.)',
    minWidth: 100,
    format: (value: number) => value + ' л.',
  },
  {
    id: 'sum',
    label: 'Сумма (₽)',
    minWidth: 100,
    format: (value: number) => value + ' ₽',
  },
  {
    id: 'literPrice',
    label: 'Цена за литр (₽)',
    minWidth: 100,
    format: (value: number) =>
      //@ts-ignore
      value !== '0' ? value + ' ₽' : '-',
  },
  {
    id: 'type_transaction',
    label: 'Тип транзакции',
    format: value => Transactions(value),
  },
  {
    id: 'time_and_date',
    label: 'Дата и Время',
    minWidth: 120,
    align: 'right',
    //@ts-ignore
    format: (value: string) =>
      format(new Date(value), 'dd.MM.yyyy в HH:mm', { locale: ru }),
  },
  {
    id: 'id',
    label: '',
    minWidth: 50,
    //@ts-ignore
    format: (value, data_print) =>
      (data_print.operationType === 2 || data_print.operationType === 4) && (
        <div
          onClick={() => {
            getPrintCheck({
              station: {
                id: data_print.petrolStation.id,
                title: data_print.petrolStation.title,
                address: data_print.petrolStation.address,
              },
              id_check: data_print?.id,
              number: data_print?.card_number,
              date: format(
                new Date(data_print?.time_and_date),
                'dd.MM.yyyy в HH:mm'
              ),
              type: getOperationType(data_print.operationType),
              operator: getUserName(data_print?.employee),
              write: {
                title: data_print.fuel,
                amount: Math.abs(data_print.literAmount),
              },
              balance: {
                title: data_print?.fuel,
                amount: data_print?.remainderLiterAmount,
              },
            })
          }}
          title={'Печать чека'}
          className={'button-print'}
        >
          <LocalPrintshopIcon htmlColor={'white'} />
        </div>
      ),
  },
]
const defaultParams = {
  dateTo: endOfMonth(new Date()),
  dateFrom: startOfMonth(new Date()),
}

const getParams = (values: any) => {
  let params = new URLSearchParams('')

  for (let i = 0; i < Object.keys(values).length; i++) {
    Object.entries(values).map((value, key) => {
      if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
        //@ts-ignore
        params.set(value[0], new Date(value[1]).toJSON())
      } else if (value[1]) {
        params.set(value[0], value[1] as string)
      }
    })
  }
  return params.toString()
}

export const ClientTransactionTable = (props: {
  id: any
  customer: string
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [paramsQuery, setParams] = React.useState<any>(
    getParams({ customerID: props.id, ...defaultParams })
  )

  const { data, search }: { data: any; search: any } = useRequest({
    url: `/card-transaction-histories?${paramsQuery}&countPerPage=${rowsPerPage}&page=${
      page + 1
    }`,
  })

  React.useEffect(() => {
    search(
      `/card-transaction-histories?${paramsQuery}&countPerPage=${rowsPerPage}&page=${
        page + 1
      }`
    )
  }, [paramsQuery, page, rowsPerPage])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const { data: petrolStation }: { data: any } = useRequest({
    url: '/petrol-stations',
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      petrolStationID: null,
      cardNumber: null,
      customerID: props.id,
      dateTo: endOfMonth(new Date()),
      dateFrom: startOfMonth(new Date()),
    },
  })

  const resetFilter = () => {
    reset({
      cardNumber: null,
      petrolStationID: null,
      customerID: props.id,
      //@ts-ignore
      dateFrom: null,
      //@ts-ignore
      dateTo: null,
    })
    setParams(`customerID=${props.id}`)
  }

  const onSubmit = (values: any) => {
    let params = new URLSearchParams('')

    for (let i = 0; i < Object.keys(values).length; i++) {
      Object.entries(values).map((value, key) => {
        if ((value[0] === 'dateTo' || value[0] === 'dateFrom') && value[1]) {
          //@ts-ignore
          params.set(value[0], new Date(value[1]).toJSON())
        } else if (value[1]) {
          params.set(value[0], value[1] as string)
        }
      })
    }
    setParams(params.toString())
  }

  function getOrderProtocolData() {
    searchTransactions(
      `?${getParams(getValues())}&countPerPage=${999}&page=${0}`
    ).then(dataRequest => {
      getPrintClientTransactions({
        filters: getValues(),
        record: dataRequest.data,
        customer: props.customer,
      })
    })
  }

  if (!data) {
    return <div>Loading...</div>
  }
  return (
    <React.Fragment>
      <div className={'na-filter'} style={{ marginBottom: 30 }}>
        <FormControl fullWidth className={'mui-input'}>
          <div className={'na-filter__fields'}>
            <div>
              <TextInputRa
                name={'cardNumber'}
                label={'Номер карты'}
                control={control}
                errors={errors}
                placeholder={'Введите номер карты'}
              />
            </div>
            <div>
              <TextInputSelectRa
                name={'petrolStationID'}
                control={control}
                label={'Станция АЗС'}
                errors={errors}
                options={
                  petrolStation?.items?.map((item: any) => ({
                    id: item.id,
                    name: item.title,
                  })) || []
                }
              />
            </div>
            <div>
              <TextInputDateRa
                name={'dateFrom'}
                control={control}
                label={'Дата от'}
                errors={errors}
              />
            </div>
            <div>
              <TextInputDateRa
                name={'dateTo'}
                control={control}
                label={'Дата до'}
                errors={errors}
              />
            </div>
          </div>

          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Применить
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={resetFilter}
            >
              Очистить
            </Button>
          </div>
        </FormControl>
      </div>
      <div
        style={{ width: 200, marginTop: 20, marginBottom: 20, display: 'flex' }}
      >
        <Button
          variant="contained"
          style={{ minWidth: 170 }}
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={() =>
            getPrintTransactions({
              filters: getValues(),
              id: props.id,
            })
          }
        >
          Печать отчета
        </Button>
        <Button
          variant="contained"
          style={{ minWidth: 170, marginLeft: 16 }}
          className={'btn-default btn-green bnt-icon-ok'}
          onClick={getOrderProtocolData}
        >
          Печать протокола
        </Button>
      </div>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.items
                  ?.map((item: any) => ({
                    ...item,
                    id: item.id,
                    responsible: getUserName({ ...item.employee }),
                    balance: 0,
                    type_transaction: item.operationType,
                    sum: item.amount,
                    literAmount:
                      item.operationType === 1
                        ? getLiterAmount(
                            Number(item.amount) / Number(item.literPrice)
                          )
                        : item.literAmount,
                    literPrice: item.literPrice,
                    time_and_date: item.createdAt,
                    fuel: item?.fuel?.title || 'Удален',
                    station: getStation(item.petrolStation),
                    card_number: formatNumber(item?.card?.number, ' '),
                  }))
                  .map((row: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map(column => {
                          const value = row[column.id]
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(value as any, row)
                                : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </React.Fragment>
  )
}
