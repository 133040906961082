import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'

import { Alert, Button } from '@mui/material'

import { GoBack } from '../../components/go-back'
import { TitleComponent } from '../../components/title'
import { ComponentListCard } from './components'
import { SwitchFieldRa } from '../../components/inputs/switch-field'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { TextInputRa } from '../../components/inputs/text-field'
import { CheckboxInputRa } from '../../components/inputs/checkbox-field'

import FormCard from './form-card'

import { useRequest } from '../../request/use-request'

import FormAddedFuel from './added-fuel'

import { phoneClear } from '../../formatters/clear-phone'

import FormCorrectBalance from './correct-fuel'

import { schema } from './schema'

import { Client } from '../../types/clients'

import './style.scss'

type Props = {
  title?: string
  defaultValues?: Client
  onSubmit: (props: any, setErrors?: any) => void
}

const Form = (props: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: props.defaultValues,
  })

  const [errorForm, setErrorForm] = React.useState({
    status: false,
    message: '',
  })
  const { data, search }: { data: any; search: () => void } = useRequest({
    url: `/cards?customerID=${props?.defaultValues?.id || null}`,
  })

  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [openFuel, setOpenFuel] = React.useState(false)
  const [openCorrect, setOpenCorrect] = React.useState({
    status: false,
    uuid: null,
  })
  const [uuid, setUuid] = React.useState(null)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleCloseFuel = () => {
    setOpenFuel(false)
    search()
  }
  const handleCloseCorrect = () => {
    setOpenCorrect({ status: false, uuid: null })
    search()
  }
  const handleOpenCorrect = (params: any) => {
    setOpenCorrect({ status: true, uuid: params.uuid })
  }
  const handleOpenFuel = (id: any) => {
    setUuid(id)
    setOpenFuel(true)
  }
  const handleClose = () => {
    setOpen(false)
    search()
    setUuid(null)
  }

  const onSubmit = (values: any) => {
    props.onSubmit(
      {
        ...values,
        subjectForm: values.subjectForm ? 2 : 1,
        phone: phoneClear(values.phone),
      },
      (error: any) => {
        if (typeof error === 'string') {
          setErrorForm({ status: true, message: error })
          setTimeout(() => setErrorForm({ status: false, message: '' }), 3000)
        }
        if (error) {
          Object.entries(error).map((item: any) =>
            setError(item[0], { message: item[1] })
          )
        }
      }
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errorForm.status && (
        <Alert variant="filled" severity="error">
          {errorForm.message}
        </Alert>
      )}

      {watch('id') && (
        <FormCorrectBalance
          open={openCorrect.status}
          handleClose={handleCloseCorrect}
          balances={data?.items || []}
          uuid={openCorrect.uuid}
        />
      )}
      {watch('id') && (
        <FormAddedFuel
          open={openFuel}
          handleClose={handleCloseFuel}
          defaultValues={{ uuid: uuid as any }}
        />
      )}
      <FormCard
        open={open}
        handleClose={handleClose}
        defaultValues={{ customerID: Number(watch('id')) }}
      />
      <div className={'client-form'}>
        <div>
          <GoBack />
        </div>
        <div className={'client-form__block-title'}>
          <TitleComponent title={props.title || ''} />
        </div>
        <div className={'client-form__block-two-column'}>
          <div className={'client-form__block-two-column__left'}>
            <div>
              <TextInputRa
                name={'lastName'}
                label={'Фамилия клиента'}
                control={control}
                errors={errors}
                autoFocus={true}
              />
              <TextInputRa
                name={'firstName'}
                label={'Имя клиента'}
                control={control}
                errors={errors}
              />
              <TextInputRa
                name={'middleName'}
                label={'Отчество клиента'}
                control={control}
                errors={errors}
              />
              <CheckboxInputRa
                name={'subjectForm'}
                label={'Юридическое лицо'}
                control={control}
              />
            </div>

            <div>
              <TextInputPhoneRa
                name={'phone'}
                label={'Телефон клиента'}
                control={control}
                errors={errors}
              />
              <SwitchFieldRa
                name={'discountType'}
                label={'Тип скидки'}
                control={control}
                errors={errors}
              />
              <TextInputRa
                name={'discount'}
                label={'Размер скидки'}
                control={control}
                errors={errors}
                placeholder={'Размер скидки'}
              />
              {watch('subjectForm') && (
                <TextInputRa
                  name={'companyTitle'}
                  label={'Наименование компании'}
                  control={control}
                  errors={errors}
                />
              )}
              {watch('subjectForm') && (
                <TextInputRa
                  name={'companyInn'}
                  label={'ИНН компании '}
                  control={control}
                  errors={errors}
                />
              )}
            </div>
            <div className={'client-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={() => navigate(-1)}
              >
                Закрыть
              </Button>
            </div>
          </div>
        </div>
      </div>
      {watch('id') && (
        <ComponentListCard
          handleOpenCorrect={handleOpenCorrect}
          handleOpenFuel={handleOpenFuel}
          handleOpen={handleOpen}
          cards={data?.items ?? []}
        />
      )}
    </form>
  )
}
export default Form
