import axios from 'axios'
import env from '../env'
import { Employee } from '../types/employee'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const listEmployee = () => {
  return baseURL().get(`employees`)
}
export const addedEmployee = (props: Employee) => {
  return baseURL().post(`employees`, { ...props })
}
export const listEmployeePetrol = (props: { id: number }) => {
  return baseURL().get(`employees?petrolStationID=${props.id}`)
}
export const updateEmployee = (props: Employee) => {
  return baseURL().patch(`employees/${props.id}`, { ...props })
}
export const deleteEmployee = (props: { id: number }) => {
  return baseURL().delete(`employees/${props.id}`)
}
