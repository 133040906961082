export const getUserNameOrCompany = ({
  firstName,
  id,
  lastName,
  middleName,
  phone,
  roles,
  username,
  subjectForm,
  companyInn,
  companyTitle,
}: any) => {
  if (subjectForm === 2) {
    return companyTitle
  }
  let fio = []

  if (lastName) {
    fio.push(lastName)
  }
  if (firstName) {
    fio.push(firstName)
  }
  if (middleName) {
    fio.push(middleName)
  }
  return fio.join(' ')
}
