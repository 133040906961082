import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { yupResolver } from '@hookform/resolvers/yup'

import { Box, Button, FormControl, Modal } from '@mui/material'

import { TextInputRa } from '../../components/inputs/text-field'
import { getCheckPrintComponent } from '../../components/checks'

import { style } from '../../styles/style'

import { schemaRefunds } from './schema'

import { refundsCardTransaction } from '../../request/transaction'

import { getFormatDate } from '../../helpers/getFormatDate'
import { getPetrolStation } from '../../helpers/getPetrolStation'
import { getUserNameLocalStorage } from '../../helpers/getUserNameLocalStorage'

import { getLiterAmount } from '../../formatters/liter-amount'

type Props = {
  open: boolean
  handleClose: () => void
  defaultValues: {
    id: number
    numberCard: string
    balanceTitle: string
  }
  getReport: () => void
}

const FormRefunds = ({
  open,
  handleClose,
  defaultValues,
  getReport,
}: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaRefunds),
    defaultValues: {
      literAmount: null,
    },
  })

  const navigate = useNavigate()

  function closeModal() {
    handleClose()
    reset()
  }

  const onSubmit = (values: any) => {
    refundsCardTransaction({ ...values, id: defaultValues.id })
      .then(({ data }) => {
        getCheckPrintComponent({
          operation_type: data.operationType,
          station: {
            title: getPetrolStation('title'),
            address: getPetrolStation('address'),
          },
          id_check: data.parentID,
          number: defaultValues?.numberCard,
          date: getFormatDate({
            date: new Date().toString(),
            type: 'dd.MM.yyyy в HH:mm',
          }),
          operator: getUserNameLocalStorage(),
          write: {
            title: defaultValues.balanceTitle,
            amount: data.literAmount as any,
          },
          balance: {
            title: defaultValues.balanceTitle,
            amount: data.remainderLiterAmount as any,
          },
        })

        closeModal()
        getReport()
        return navigate('/')
      })
      .catch(e => {
        setError('literAmount', { message: e.response?.data?.description })
      })
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <h2
          className={'medium-text'}
          style={{ fontSize: 22, fontWeight: 'bold' }}
          id="medium-text"
        >
          Возврат баланса
        </h2>
        <FormControl fullWidth className={'mui-input'}>
          <TextInputRa
            name={'literAmount'}
            label={'Реальный литраж транзакции'}
            control={control}
            errors={errors}
          />
          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Возврат
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={closeModal}
            >
              Отмена
            </Button>
          </div>
        </FormControl>
      </Box>
    </Modal>
  )
}

export default FormRefunds
