import Person2Icon from '@mui/icons-material/Person2'
import AddCardIcon from '@mui/icons-material/AddCard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import EvStationIcon from '@mui/icons-material/EvStation'
import PaidIcon from '@mui/icons-material/Paid'
import {
  CardTransactionHistoryReadList,
  CustomerRead,
  EmployeeRead,
  FuelRead,
  PetrolStationRead,
  ReportFuelConsumptionRead,
} from '../../permission'

export const const_menu = [
  {
    id: 1,
    name: 'Рабочий стол',
    link: '/',
    permission: null,
    icon: AddCardIcon,
  },
  {
    id: 2,
    name: 'Клиенты',
    link: '/clients',
    permission: CustomerRead,
    icon: Person2Icon,
  },
  {
    id: 3,
    name: 'Сотрудники',
    link: '/users',
    permission: EmployeeRead,
    icon: PeopleAltIcon,
  },
  {
    id: 4,
    name: 'АЗC',
    link: '/stations',
    permission: PetrolStationRead,
    icon: LocalGasStationIcon,
  },
  {
    id: 5,
    name: 'Виды топлива',
    link: '/fuels',
    permission: FuelRead,
    icon: EvStationIcon,
  },
  {
    id: 6,
    name: 'Список транзакций',
    link: '/transactions',
    permission: CardTransactionHistoryReadList,
    icon: PaidIcon,
  },
  {
    id: 7,
    name: 'Смены',
    link: '/report-shifts',
    permission: ReportFuelConsumptionRead,
    icon: FormatListBulletedIcon,
  },
  {
    id: 7,
    name: 'Отчет',
    link: '/reports',
    permission: ReportFuelConsumptionRead,
    icon: FormatListBulletedIcon,
  },
]
