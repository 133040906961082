import { format } from 'date-fns'
import printJS from 'print-js'
import { getUserNameLocalStorage } from './getUserNameLocalStorage'
import { getUserNameOrCompany } from './getUserNameOrCompany'

export function getPrintReports(props: { reports: any; filter: any[] }) {
  let str = []

  let detail = []

  for (let i = 0; props.reports?.customerFuelConsumptions?.length > i; i++) {
    const elem = props.reports?.customerFuelConsumptions[i]
    const name = getUserNameOrCompany(elem.customer)
    let params = []
    for (let j = 0; elem.fuelConsumptions?.length > j; j++) {
      params.push(
        `<div style='margin-right:10px;font-size:14px; font-weight:500; color:var(--color-text); padding-right:5px; border-right:1px solid gray;'>${
          elem?.fuelConsumptions?.[j]?.fuel
        } / ${Math.abs(elem?.fuelConsumptions?.[j]?.literAmountSum)} л.</div>`
      )
    }
    detail.push(
      `<div><div style="display:flex; margin-bottom:10px"><div style='margin-right:30px;width:350px'>${
        i + 1
      }) ${name}</div><div style='display:flex'>${params.join(
        ''
      )}</div></div></div`
    )
  }
  for (let i = 0; props.reports?.totalFuelConsumptions?.length > i; i++) {
    str.push(
      `<div>${props.reports?.totalFuelConsumptions?.[i].fuel} = ${Math.abs(
        props.reports?.totalFuelConsumptions?.[i].literAmountSum
      )} л. </div>`
    )
  }
  let filter = []
  for (let i = 0; props.filter.length > i; i++) {
    filter.push(`<div>${props.filter[i].title}: ${props.filter[i].value}</div>`)
  }

  printJS({
    printable: `<div id="print-content" className={'print-content'}>
            <div>Отчет составлен ${format(
              new Date(),
              'dd.MM.yyyy в HH:mm'
            )}</div>
           
            <hr></hr>
            <div>Составил ${getUserNameLocalStorage()}</div>
            ${filter.join('')}
            <br></br>
            <div>Детализация:</div>
            <div >${detail.join('')}</div>
            <br></br>
            <div>Итого литров заправлено:</div>
            ${str.join('')}
          </div>`,
    type: 'raw-html',
  })
}
