import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { Box, Button, Modal, FormHelperText } from '@mui/material'
import './style.scss'
import { style } from '../../styles/style'
import { TextInputRa } from '../../components/inputs/text-field'
import { TextInputSelectRa } from '../../components/inputs/select-field'
import { addedEmployee, updateEmployee } from '../../request/employee'
import { useRequest } from '../../request/use-request'
import { PatrolStation } from '../../types/patrol-station'
import { Role } from '../../types/role'
import { schemaUpdate } from './schema-update'
import { TextInputPhoneRa } from '../../components/inputs/text-field-phone'
import { phoneClear } from '../../formatters/clear-phone'

type Props = {
  title?: string
  open: boolean

  handleClose: () => void
  data?: {
    id: number | null
    petrol_station_id?: number | null
    last_name?: string | null
    first_name?: string | null
    middle_name?: string | null
    password?: string | null
    role: string | null
    phone: string | null
    username: string | null
  }
}

const FormModal = (props: Props) => {
  const [errorForm, setErrorForm] = React.useState({
    status: false,
    message: '',
  })
  const { data }: { data: PatrolStation | any } = useRequest({
    url: '/petrol-stations',
  })
  const { data: roles }: { data: { items: Role[] } | any } = useRequest({
    url: '/roles',
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(props?.data?.id ? schemaUpdate : schema),
    defaultValues: props.data,
  })

  const onSubmit = (values: any) => {
    if (!watch('id')) {
      addedEmployee({
        ...values,
        phone: phoneClear(values.phone),
        roleIds: [Number(values.roleIds)],
      })
        .then(() => {
          props.handleClose()
          reset()
        })
        .catch(error => {
          if (typeof error?.response?.data?.description === 'string') {
            setErrorForm({
              status: true,
              message: error?.response?.data?.description,
            })
            setTimeout(() => setErrorForm({ status: false, message: '' }), 3000)
          }
          if (error?.response?.data?.description) {
            Object.entries(error?.response?.data?.description).map(
              (item: any) => setError(item[0], { message: item[1] })
            )
          }
        })
    } else {
      delete values.roles
      updateEmployee({
        ...values,
        phone: phoneClear(values.phone),
        roleIds: [Number(values.roleIds)],
      })
        .then(() => {
          props.handleClose()
          reset()
        })
        .catch(error => {
          if (typeof error?.response?.data?.description === 'string') {
            setErrorForm({
              status: true,
              message: error?.response?.data?.description,
            })
            setTimeout(() => setErrorForm({ status: false, message: '' }), 3000)
          }
          if (error?.response?.data?.description) {
            Object.entries(error?.response?.data?.description).map(
              (item: any) => setError(item[0], { message: item[1] })
            )
          }
        })
    }
  }

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose()
          reset({ first_name: null })
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...style,
              maxWidth: 600,
              maxHeight: '90%',
              overflowY: 'auto',
              minWidth: 400,
              padding: '30px 70px',
            }}
          >
            <h2
              className={'medium-text'}
              style={{ fontSize: 22, fontWeight: 'bold', marginBottom: '40px' }}
              id="medium-text"
            >
              {!watch('id')
                ? 'Добавление сотрудника'
                : 'Редактировать сотрудника'}
            </h2>
            <TextInputRa
              name={'lastName'}
              control={control}
              label={'Фамилия сотрудника'}
              errors={errors}
            />
            <TextInputRa
              name={'firstName'}
              control={control}
              label={'Имя сотрудника'}
              errors={errors}
            />
            <TextInputRa
              name={'middleName'}
              control={control}
              label={'Отчетство сотрудника'}
              errors={errors}
            />
            <TextInputPhoneRa
              name={'phone'}
              control={control}
              label={'Телефон'}
              errors={errors}
            />
            <TextInputRa
              name={'username'}
              control={control}
              label={'Имя для авторизации'}
              errors={errors}
            />
            {!watch('id') && (
              <TextInputRa
                name={'password'}
                control={control}
                label={'Пароль сотрудника'}
                errors={errors}
              />
            )}
            {data && data.items && (
              <TextInputSelectRa
                name={'petrolStationID'}
                control={control}
                label={'Закреплен за АЗС'}
                errors={errors}
                options={data.items.map((item: any) => ({
                  id: item.id,
                  name: item.title + ` (${item.address})`,
                }))}
              />
            )}
            {roles && (
              <TextInputSelectRa
                name={'roleIds'}
                control={control}
                label={'Роль сотрудника'}
                errors={errors}
                options={roles.items.map((item: Role) => ({
                  id: item.id,
                  name: item.description,
                }))}
              />
            )}
            <div>
              <FormHelperText variant={'filled'}>
                <div className={'text-error'}>{errorForm.message}</div>
              </FormHelperText>
            </div>
            <div className={'station-form__block-buttons'}>
              <Button
                variant="contained"
                className={'btn-default btn-blue bnt-icon-ok'}
                onClick={handleSubmit(onSubmit)}
              >
                {watch('id') ? 'Изменить' : 'Добавить'}
              </Button>
              <Button
                variant="contained"
                className={'btn-default btn-gray btn-icon-cancel'}
                onClick={() => {
                  props.handleClose()
                  reset()
                }}
              >
                Отмена
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  )
}
export default FormModal
