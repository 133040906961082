import { format } from './format'

export const numDecimals = (num: number) => (value: number) =>
  (Math.round(value * Math.pow(10, num)) / Math.pow(10, num)).toFixed(num)

export const formatMoney = (sum: number) => {
  if (Math.sign(sum) === 1 || Math.sign(sum) === 0) {
    return format(sum)
  } else {
    return '-' + format(Math.abs(sum))
  }
}
