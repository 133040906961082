import React from 'react'
import { Employee } from '../types/employee'

export const EmployeeCreate = 'EMPLOYEE_CREATE'
export const EmployeeUpdate = 'EMPLOYEE_UPDATE'
export const EmployeeDelete = 'EMPLOYEE_DELETE'
export const EmployeeRead = 'EMPLOYEE_READ'

export const PetrolStationCreate = 'PETROL_STATION_CREATE'
export const PetrolStationUpdate = 'PETROL_STATION_UPDATE'
export const PetrolStationDelete = 'PETROL_STATION_DELETE'
export const PetrolStationRead = 'PETROL_STATION_READ'

export const CustomerCreate = 'CUSTOMER_CREATE'
export const CustomerUpdate = 'CUSTOMER_UPDATE'
export const CustomerDelete = 'CUSTOMER_DELETE'
export const CustomerRead = 'CUSTOMER_READ'
export const CardDelete = 'CARD_DELETE'

export const FuelCreate = 'FUEL_CREATE'
export const FuelUpdate = 'FUEL_UPDATE'
export const FuelDelete = 'FUEL_DELETE'
export const FuelRead = 'FUEL_READ'
export const CardUpdate = 'CARD_UPDATE'
export const CardReadList = 'CARD_READ_LIST'
export const CardReadItem = 'CARD_READ_ITEM'
export const CardReadItemWithoutPinCode = 'CARD_READ_ITEM_WITHOUT_PIN_CODE'
export const CardCreate = 'CARD_CREATE'
export const CardDetailBalanceTopUp = 'CARD_DETAIL_BALANCE_TOP_UP'
export const CardDetailBalanceWithdraw = 'CARD_DETAIL_BALANCE_WITHDRAW'
export const CardTransactionHistoryReadList =
  'CARD_TRANSACTION_HISTORY_READ_LIST'
export const CardTransactionHistoryReadWorkingShiftList =
  'CARD_TRANSACTION_HISTORY_READ_WORKING_SHIFT_LIST'

export const RoleRead = 'ROLE_READ'

export const ReportFuelConsumptionRead = 'REPORT_FUEL_CONSUMPTION_READ'

export const WorkingShiftOpen = 'WORKING_SHIFT_OPEN'
export const WorkingShiftClose = 'WORKING_SHIFT_CLOSE'
export const WorkingShiftListRead = 'WORKING_SHIFT_LIST_READ'

export function permission(PAGES: string) {
  return JSON.parse(localStorage.getItem('user') as any)?.permissions?.some(
    (permission: string) => permission === PAGES
  )
}
