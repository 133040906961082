import axios from 'axios'
import env from '../env'
import { Client } from '../types/clients'

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const listClients = () => {
  return baseURL().get(`customers`)
}
export const addedClients = (props: Client) => {
  return baseURL().post(`customers`, {
    ...props,
    discountType: props.discountType ? 2 : 1,
    discount: Number(props.discount),
  })
}
export const getBalance = (props: { uuid: string }) => {
  return baseURL().get(`cards/${props.uuid}/detail-balance`)
}

export const addedBalance = (props: {
  uuid?: string
  fuelID: string
  amount: string
  purchasePrice?: number
}) => {
  const uuid = props.uuid
  delete props.uuid
  return baseURL().post(`cards/${uuid}/detail-balance`, {
    ...props,
  })
}
export const correctBalance = (props: {
  uuid?: string
  id: string
  amount: string
}) => {
  return baseURL().patch(`cards/${props.uuid}/detail-balance/${props.id}`, {
    amount: props.amount,
  })
}

export const writeOffBalance = (props: {
  uuid: string
  literAmount: string
  id: number
}) => {
  return baseURL().post(`cards/${props.uuid}/detail-balance/${props.id}`, {
    literAmount: Number(props.literAmount),
  })
}

export const updateClients = (props: Client) => {
  return baseURL().patch(`customers/${props.id}`, {
    ...props,
    discountType: props.discountType ? 2 : 1,
    discount: Number(props.discount),
  })
}

export const deleteClients = (props: { id: number }) => {
  return baseURL().delete(`customers/${props.id}`)
}
export const oneClient = (props: { id: number }) => {
  return baseURL().get(`customers/${props.id}`)
}
