import printJS from 'print-js'
import React from 'react'
import { getOperationType } from '../../const/type-transactions'
import { formatMoney } from '../../formatters/formatMoney'

type Props = {
  station: {
    title: string
    address: string
  }
  id_check: string
  number: string
  date: string
  operator: string
  operation_type: 1 | 2 | 3 | 4
  balance: {
    amount: number | string
    title: string
  }
  write: {
    title: string
    amount: number | string
  }
}

export const getCheckPrintComponent = (props: Props) => {
  return printJS({
    printable: `<div style="display: flex; border-bottom: 1px dashed gray;">
        <div><div style="text-align: center; margin-top: 10px;">Топливная карта Evolution</div>
        <hr><div style="display: flex;">
        <div style="width: 180px; display: flex; flex-direction: column;">
        <div style="margin-top: 5px; font-size: 14px;">${
          props.station.title
        }</div>
        <div style="margin-top: 5px; font-size: 14px;">Чек №${
          props?.id_check || '-'
        }</div>
        <div style="margin-top: 5px; font-size: 14px;">Карта ${
          props.number
        }</div></div>
        <div style="width: 180px; display: flex; flex-direction: column;">
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">${
          props.station.address
        }</div>
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">${
          props.date
        }</div>
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">${
          props.operator
        }</div></div></div>
        <div style="text-align: center; margin-top: 10px; text-decoration: underline; margin-bottom: 10px;">Операция:</div>
        <div style="display: flex;"><div style="width: 180px; display: flex; flex-direction: column;">
        <div style="font-size: 14px;">${getOperationType(
          props.operation_type
        )}</div></div>
        <div style="width: 180px; display: flex; flex-direction: column;">
        <div style="font-size: 14px; text-align: right;">${
          props.write.title
        } / ${props.write.amount} л</div></div></div>
        <div style="text-align: center; margin-top: 10px; text-decoration: underline; margin-bottom: 10px;">Остаток:</div>
        <div style="text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 14px;">${
          props.balance.title
        } / ${props.balance.amount} л</div>
        </div><div style="padding-left: 10px; margin-left: 10px; border-left: 1px dashed gray; padding-right: 10px;">
        <div style="text-align: center; margin-top: 10px;">Топливная карта Evolution</div><hr>
        <div style="display: flex;"><div style="width: 180px; display: flex; flex-direction: column;">
        <div style="margin-top: 5px; font-size: 14px;">${
          props.station.title
        }</div>
        <div style="margin-top: 5px; font-size: 14px;">Чек №${
          props?.id_check || '-'
        }</div>
        <div style="margin-top: 5px; font-size: 14px;">Карта ${
          props.number
        }</div></div>
        <div style="width: 180px; display: flex; flex-direction: column;">
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">${
          props.station.address
        }</div>
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">${
          props.date
        }</div>
        <div style="margin-top: 5px; text-align: right; font-size: 14px;">Оператор: ${
          props.operator
        }</div>
        </div></div>
        <div style="text-align: center; margin-top: 10px; text-decoration: underline; margin-bottom: 10px;">Операция:</div>
        <div style="display: flex;"><div style="width: 180px; display: flex; flex-direction: column;">
        <div style="font-size: 14px;">${getOperationType(
          props.operation_type
        )}</div></div><div style="width: 180px; display: flex; flex-direction: column;">
        <div style="font-size: 14px; text-align: right;">${
          props.write.title
        } / ${props.write.amount} л</div></div>
        </div><div style="text-align: center; margin-top: 10px; text-decoration: underline; margin-bottom: 10px;">Остаток:</div>
        <div style="text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 14px;">${
          props.balance.title
        } / ${formatMoney(Number(props.balance.amount))} л</div>
        </div></div>`,
    type: 'raw-html',
  })
}
