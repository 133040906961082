import axios from 'axios'
import env from '../env'

const baseURLNotToken = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {},
  })

const baseURL = () =>
  axios.create({
    baseURL: env.baseUrl,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

export const requestAuth = ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  return baseURLNotToken().post(`employee-tokens`, {
    username: username,
    password: password,
  })
}

export const userMe = () => {
  return baseURL().get(`current-employees`)
}

export const petrolStations = () => {
  return baseURL().get(`petrol-stations`)
}

type Station = { address: 'string'; phone: 'string'; title: 'string' }

export const addedPetrolStations = (props: Station) => {
  return baseURL().post(`petrol-stations`, { ...props })
}

export const deletePetrolStation = (props: { id: number }) => {
  return baseURL().delete(`petrol-stations/${props.id}`)
}

export const onePetrolStation = (props: { id: number }) => {
  return baseURL().get(`petrol-stations/${props.id}`)
}

export const fuelsPetrolStation = (props: { id: number }) => {
  return baseURL().get(`petrol-stations/${props.id}/fuels`)
}

export const updatePetrolStation = (props: Station & { id: number }) => {
  return baseURL().put(`petrol-stations/${props.id}`, { ...props })
}
export const addedFuelsInPetrolStation = (props: {
  id: number
  fuelIDs: any[]
}) => {
  return baseURL().post(`petrol-stations/${props.id}/fuels`, { ...props })
}
export const updateFuelsInPetrolStation = (props: {
  id: number
  data: { fuelID: number; price: number }[]
}) => {
  return baseURL().patch(`petrol-stations/${props.id}/fuels`, props.data)
}
