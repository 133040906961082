import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { Controller } from 'react-hook-form'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type Props = {
  name: string
  control: any
  label: string
  errors: any
  placeholder?: string
  options: { id?: string | number; name?: string | number }[] | []
}

export const TextInputSelectCheckboxRa = (props: Props) => {
  return (
    <div>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => {
          return (
            <div className={'mui-input'}>
              <label className={'mui-input__label small-text'}>
                {props.label}
              </label>
              <Select
                {...field}
                className={'mui-input__select'}
                displayEmpty={true}
                style={{ maxWidth: 420 }}
                multiple
                renderValue={selected =>
                  selected
                    ?.map((select: any) => {
                      return props?.options?.filter(
                        option => option.id === select
                      )?.[0]?.name
                    })
                    .join(', ')
                }
                MenuProps={MenuProps}
              >
                {props?.options?.map((option: any) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <Checkbox checked={field?.value?.indexOf(option.id) > -1} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
              <div className={'text-error'}>
                {props.errors[field.name]?.message}
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
