export const numDecimals = (num: number) => (value: number) => {
  return (Math.round(value * Math.pow(10, num)) / Math.pow(10, num)).toFixed(
    num
  )
}

export const formatNumber = (value: any, symbol?: any) => {
  return String(value)
    .replace(/(\d{3})/g, '$1,')
    .split(',')
    .join(symbol || ' - ')
}

export const format = (value: number) => {
  let res: number[] = []
  let cur = value
  do {
    res.push(Number(numDecimals(2)(cur % 1000)))
    cur = Math.floor(cur / 1000)
  } while (cur > 0)
  return res
    .reverse()
    .map((item, index) =>
      index > 0
        ? Array.from(Array(Math.max(3 - Math.ceil(item).toString().length, 0)))
            .map(_ => '0')
            .join('') + item
        : item
    )
    .join('\u00A0')
}
