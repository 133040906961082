import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { GoBack } from '../../components/go-back'
import { TitleComponent } from '../../components/title'
import { formatMoney } from '../../formatters/formatMoney'
import { getUserName } from '../../helpers/getUserName'
import { permission, PetrolStationUpdate } from '../../permission'
import { deletePetrolStation } from '../../request'
import { useRequest } from '../../request/use-request'
import { Employee } from '../../types/employee'
import { Station } from '../../types/patrol-station'
import './style.scss'
import EditIcon from '@mui/icons-material/Edit'
import { ModalUpdateFuels } from './modal-fuels'

const Card = (props: { value: string; title: string }) => (
  <div className={'card-petrol'}>
    <div className={'card-petrol__title'}>{props.title}:</div>
    <div className={'card-petrol__value'}>{props.value}</div>
  </div>
)
const CardUser = (props: { id: number; name: string; phone: string }) => (
  <div className={'card-user'}>
    <div>{props.id}</div>
    <div>{props.name}</div>
    <div>{props.phone}</div>
  </div>
)

const ShowStation = () => {
  const [openModalUpdateFuels, setOpenModalUpdateFuels] = React.useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loader }: { data: Station | any; loader: boolean } = useRequest(
    { url: `/petrol-stations/${id}` }
  )
  const { data: fuels, search }: { data: any; search: any } = useRequest({
    url: `petrol-stations/${id}/fuels`,
  })
  const {
    data: employees,
    loader: loaderEmployees,
  }: { data: any; loader: any } = useRequest({
    url: `employees?petrolStationID=${id}`,
  })
  if (loader || !data || loaderEmployees) {
    return <CircularProgress />
  }
  const handleClose = () => {
    setOpenModalUpdateFuels(false)
    search(`petrol-stations/${id}/fuels`)
  }
  return (
    <div className={'station-show container'}>
      {fuels && (
        <ModalUpdateFuels
          id={Number(id)}
          data={fuels?.map(
            (fuel: {
              fuel: { id: number; title: string }
              petrolStationID: number
              price: string
            }) => ({
              id: fuel.fuel.id,
              name: fuel.fuel.title,
              price: fuel.price,
            })
          )}
          open={openModalUpdateFuels}
          handleClose={handleClose}
        />
      )}
      <GoBack />
      <div style={{ width: 200, position: 'absolute', right: 0, top: 40 }}>
        {permission(PetrolStationUpdate) && (
          <Button
            variant="contained"
            className={'btn-default btn-green bnt-icon-ok'}
            onClick={() => {
              navigate(`/stations/edit/${id}`)
            }}
          >
            Редактировать
          </Button>
        )}
        {/* <Button
          variant="contained"
          style={{ marginTop: 20 }}
          className={'btn-default btn-red bnt-icon-ok'}
          onClick={() => {
            deletePetrolStation({ id: id as any }).then(() => {
              navigate(`/stations`)
            })
          }}
        >
          Удалить АЗС
        </Button> */}
      </div>
      <div className={'station-show__block-title'}>
        <TitleComponent title={data?.title} />
      </div>
      <div className={'station-show__block-body'}>
        <div className={'station-show__block-body__info'}>
          <Card title={'Наименование'} value={data.title} />
          <Card title={'Телефон'} value={data.phone} />
          <Card title={'Адрес'} value={data.address} />
          <Card title={'id'} value={data.id} />
        </div>
        <hr />
        <div className={'station-show__block-body__employees'}>
          <div
            className={'station-show__block-title'}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TitleComponent
              style={{ fontSize: 22, marginBottom: 10 }}
              title={'Стелла АЗС'}
            />
            {fuels && (
              <EditIcon
                color={'primary'}
                onClick={() => setOpenModalUpdateFuels(true)}
                sx={{
                  marginLeft: '20px',
                  marginTop: '10px',
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {fuels?.map((fuel: any) => (
              <div
                style={{
                  background: '#1688da',
                  backgroundImage: 'url(/assets/background/circle.svg)',
                  backgroundPosition: 'auto',
                  padding: 20,
                  margin: '0 10px',
                  display: 'flex',
                  color: 'white',
                  borderRadius: 8,
                  width: 130,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
                key={fuel.fuel.id}
              >
                <div
                  className={'medium-text'}
                  style={{
                    color: 'white',
                    fontWeight: '600',
                    borderRight: '3px solid white',
                    paddingRight: 10,
                  }}
                >
                  {fuel.fuel.title}{' '}
                </div>

                <div
                  className={'medium-text'}
                  style={{ color: 'white', paddingLeft: 10, fontWeight: '600' }}
                >
                  {formatMoney(fuel.price)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'station-show__block-body__employees'}>
          <div className={'station-show__block-title'}>
            <TitleComponent
              style={{ fontSize: 22, marginBottom: 10 }}
              title={'Список сотрудников'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'min-content',
            }}
          >
            {!employees?.items && <div style={{ width: 100 }}>Список пуст</div>}
            {employees?.items?.map((employee: Employee) => (
              <CardUser
                key={employee.id}
                name={getUserName(employee)}
                phone={employee.phone}
                id={employee.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ShowStation
