import classNames from 'classnames'
import React from 'react'
import { formatNumber } from '../../../../formatters/format'
import { formatMoney } from '../../../../formatters/formatMoney'
import { getStatus } from '../../../../hooks/useStatus'
import {
  CardDelete,
  CardUpdate,
  CustomerUpdate,
  permission,
} from '../../../../permission'
import { Balance } from '../../../../types/balance'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import GppGoodIcon from '@mui/icons-material/GppGood'
import { TableListBalances } from '../balance-card/TableListBalances'
import { ToolbarCard } from './toolbar'

type Props = {
  title: string
  numberCard: string
  statusCard: string
  updateStatus: (uuid: string, params: string) => void
  openModal: (uuid: string) => void
  uuid: string
  balances: Balance[]
  deleteCard: (uuid: string) => void
}

export const FuelCardClient = (props: Props) => {
  return (
    <div
      className={classNames('cards-client__card', {
        'cards-client__card__blocked': props.statusCard !== 'active',
      })}
    >
      <div className={'cards-client__card__number'}>
        {formatNumber(props.numberCard)}
        <div>номер карты</div>
      </div>
      <div className={'cards-client__card__number'}>
        {props.title || '-'}
        <div>Название карты</div>
      </div>
      <div
        className={'cards-client__card__status'}
        style={{ color: props.statusCard === 'active' ? '#0A5F38' : '#d22323' }}
      >
        {getStatus(props.statusCard as any)}
        <div>статус</div>
      </div>
      {permission(CustomerUpdate) && (
        <div
          onClick={() => props.openModal(props.uuid)}
          className={'cards-client__card__button-edit'}
        >
          Изменить pin-code
          <div>
            <img src={'/assets/icons/next.svg'} />
          </div>
        </div>
      )}
      {permission(CustomerUpdate) && props.statusCard === 'active' && (
        <div
          onClick={() => props.updateStatus(props.uuid, 'blocked_admin')}
          className={'cards-client__card__button-edit'}
          style={{ color: 'red' }}
        >
          Заблокировать карту
          <div>
            <RemoveCircleIcon sx={{ width: 20 }} />
          </div>
        </div>
      )}
      {permission(CustomerUpdate) && props.statusCard !== 'active' && (
        <div
          onClick={() => props.updateStatus(props.uuid, 'active')}
          className={'cards-client__card__button-edit'}
          style={{ color: 'green' }}
        >
          Разблокировать карту
          <div>
            <GppGoodIcon sx={{ width: 20 }} />
          </div>
        </div>
      )}
      {permission(CardDelete) && props.balances === null && (
        <div
          onClick={() => props.deleteCard(props.uuid)}
          className={'cards-client__card__button-edit'}
          style={{ color: 'red' }}
        >
          Удалить карту
          <div>
            <RemoveCircleIcon sx={{ width: 20 }} />
          </div>
        </div>
      )}
      <TableListBalances balances={props.balances} />
      {permission(CardUpdate) && (
        <ToolbarCard balances={props.balances} uuid={props.uuid} />
      )}
    </div>
  )
}
