import React from 'react'
import { Resolver } from 'react-hook-form'

function isAN(value: any) {
  if (!isNaN(parseFloat(value)) && isFinite(value)) return true
  else return false
}

export const customResolver: Resolver<any> = (values, _context, { names }) => {
  let errors = {}
  names?.map(name => {
    if (values[name]) {
      if (isAN(Number(values[name]))) {
        if (Math.sign(Number(values[name])) !== -1) {
        } else {
          errors = {
            ...errors,
            [name]: {
              type: 'required',
              message: 'Значение должно быть положительным',
            },
          }
        }
      } else {
        errors = {
          ...errors,
          [name]: {
            type: 'required',
            message: 'Значение должно быть числом',
          },
        }
      }
    } else {
      errors = {
        ...errors,
        [name]: {
          type: 'required',
          message: 'Поле необходимо заполнить!',
        },
      }
    }
  })

  return { values, errors }
}
