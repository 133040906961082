import React from 'react'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { setHours, setMinutes } from 'date-fns'

export const TextInputDateRa = (props: {
  name: string
  control: any
  label: string
  errors: any
  placeholder?: string
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <div className={'mui-input'}>
          <label className={'mui-input__label small-text'}>{props.label}</label>
          <DatePicker
            {...field}
            dateFormat="dd.MM.yyyy с HH:mm"
            selected={field.value}
            isClearable
            showTimeSelect
            timeFormat="HH:mm"
            injectTimes={[
              setHours(setMinutes(new Date(), 1), 0),
              setHours(setMinutes(new Date(), 5), 12),
              setHours(setMinutes(new Date(), 59), 23),
            ]}
            placeholderText={'Выберите дату'}
          />
        </div>
      )}
    />
  )
}
