import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ModalDelete } from '../../components/modal-delete'
import FormModal from './form'
import { Employee } from '../../types/employee'
import { deleteEmployee } from '../../request/employee'
import { EmployeeDelete, EmployeeUpdate, permission } from '../../permission'
import { Filter } from './filter'
import { useFilters } from '../../filters'
import { TablePagination } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { ModalChangePassword } from './modal-change-password'

interface Column {
  id: 'fio' | 'roles' | 'phone' | 'id' | 'username'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

interface Data {
  fio: string
  roles: any
  phone: string
  id: number
  username: string
}

function createData(
  fio: string,
  roles: any,
  phone: string,
  id: number,
  username: string
): Data {
  return { fio, roles, phone, id, username }
}

const ListStations = (props: {
  handleClose: () => void
  open: boolean
  handleOpen: () => void
}) => {
  const [isDelete, setIsDelete] = React.useState<{
    status: boolean
    id: null | number
  }>({
    status: false,
    id: null,
  })
  const [isUpdate, setIsUpdate] = React.useState<{
    status: boolean
    id: null | number
  }>({
    status: false,
    id: null,
  })
  const [isUpdatePassword, setIsUpdatePassword] = React.useState<{
    status: boolean
    id: null | number
  }>({
    status: false,
    id: null,
  })

  const {
    data,
    getDefaultFilter,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
    page,
    rowsPerPage,
    location,
    navigate,
    request,
  } = useFilters({ url: 'users', resource: 'employees' })

  const handleUpdate = (id: number) => {
    setIsUpdate({ status: true, id: id })
  }

  const handleOpenChangePassword = (id: number) => {
    setIsUpdatePassword({ status: true, id: id })
  }
  const handleCloseChangePassword = () => {
    setIsUpdatePassword({ status: false, id: null })
  }

  React.useEffect(() => {
    if (props.open) {
      setIsUpdate({ status: true, id: null })
    } else {
      request(location.search)
    }
  }, [props.open])

  const handleDelete = () =>
    deleteEmployee({ id: isDelete.id as any }).then(() => handleClose())

  const handleClose = () => {
    setIsDelete({ status: false, id: null })
    return request(location.search)
  }
  const handleCloseModalUpdate = () => {
    setIsUpdate({ status: false, id: null })
    props.handleClose()
    return request(location.search)
  }

  const columns: readonly Column[] = [
    { id: 'username', label: 'Имя пользователя', minWidth: 150 },
    { id: 'fio', label: 'ФИО', minWidth: 150 },
    { id: 'phone', label: 'Телефон для связи', minWidth: 150 },
    {
      id: 'roles',
      label: 'Роль',
      minWidth: 100,
      align: 'right',
      //@ts-ignore
      format: value =>
        //@ts-ignore
        value?.map(role => <span key={role.id}>{role.description}</span>) ||
        '-',
    },
    {
      id: 'id',
      label: '',
      minWidth: 120,
      align: 'right',
      //@ts-ignore
      format: value => (
        <div className={'icons-delete-edit'}>
          {permission(EmployeeUpdate) && (
            <LockIcon
              color={'primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleOpenChangePassword(value)}
            />
          )}
          {permission(EmployeeUpdate) && (
            <img
              onClick={() => handleUpdate(value)}
              src={'/assets/icons/edit.svg'}
            />
          )}
          {permission(EmployeeDelete) && (
            <img
              onClick={() => setIsDelete({ status: true, id: value })}
              src={'/assets/icons/delete.svg'}
            />
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <ModalChangePassword
        handleClose={handleCloseChangePassword}
        open={isUpdatePassword.status}
        user_id={isUpdatePassword.id as any}
      />
      <FormModal
        // @ts-ignore
        key={JSON.stringify(
          data?.items?.filter(
            (item: any) => Number(item.id) === Number(isUpdate.id)
          )?.[0]
        )}
        handleClose={handleCloseModalUpdate}
        open={isUpdate.status}
        data={
          {
            ...(data?.items?.filter(
              (item: any) => Number(item.id) === Number(isUpdate.id)
            )?.[0] as any),
            roleIds: data?.items?.filter(
              (item: any) => Number(item.id) === Number(isUpdate.id)
              //@ts-ignore
            )?.[0]?.roles?.[0]?.id,
          } || (null as any)
        }
      />
      <ModalDelete
        handleClose={handleClose}
        handleDelete={handleDelete}
        open={isDelete.status}
      />
      <Filter
        setPage={setPage}
        navigate={navigate}
        getDefaultFilter={getDefaultFilter}
        location={location}
      />
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.items
                ?.map((item: any) =>
                  createData(
                    item.lastName +
                      ' ' +
                      item.firstName +
                      ' ' +
                      item.middleName,
                    item.roles,
                    item.phone,
                    item.id,
                    item.username
                  )
                )
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format
                              ? column.format(value as any)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={data?.totalCount || '0'}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Кол-во на странице:'}
        />
      </Paper>
    </>
  )
}

export default ListStations
