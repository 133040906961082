import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { Box, Button, Modal } from '@mui/material'
import './style.scss'
import { style } from '../../styles/style'
import { TextInputRa } from '../../components/inputs/text-field'
import { addedFuels, updateFuels } from '../../request/fuels'

type Props = {
  title?: string
  open: boolean

  handleClose: () => void
  data?: {
    id: number | null
    title?: string
  }
}

const FormModal = (props: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: props.data,
  })

  const onSubmit = (values: any) => {
    if (!watch('id')) {
      addedFuels(values).then(() => {
        props.handleClose()
        reset()
      })
    } else {
      delete values.roles
      updateFuels(values).then(() => {
        props.handleClose()
        reset()
      })
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.handleClose()
        reset()
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            ...style,
            maxWidth: 600,
            maxHeight: '90%',
            overflowY: 'auto',
            minWidth: 400,
            padding: '30px 70px',
          }}
        >
          <h2
            className={'medium-text'}
            style={{ fontSize: 22, fontWeight: 'bold', marginBottom: '40px' }}
            id="medium-text"
          >
            Добавление вида топлива
          </h2>
          <TextInputRa
            name={'title'}
            control={control}
            label={'Наименование'}
            errors={errors}
          />
          <div className={'station-form__block-buttons'}>
            <Button
              variant="contained"
              className={'btn-default btn-blue bnt-icon-ok'}
              onClick={handleSubmit(onSubmit)}
            >
              Добавить
            </Button>
            <Button
              variant="contained"
              className={'btn-default btn-gray btn-icon-cancel'}
              onClick={() => {
                props.handleClose()
                reset()
              }}
            >
              Отмена
            </Button>
          </div>
        </Box>
      </form>
    </Modal>
  )
}
export default FormModal
