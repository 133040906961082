import React from 'react'
import { formatNumber } from '../formatters/format'
import './style.scss'

export const CardComponent = (item: any) => {
  return (
    <div key={item.id} className={'custom-card'}>
      <div className={'custom-card__status-block'} />
      <div className={'custom-card__logo'}>
        <img src={'/assets/logo.png'} />
      </div>
      <div className={'custom-card__number'}>
        {formatNumber(item.number, ' ')}
      </div>
      <div className={'custom-card__block-balance'}>
        <div className={'custom-card__block-balance__title'}>Баланс</div>
        <div className={'custom-card__block-balance__fuels'}>
          {item &&
            item?.balances
              ?.filter((balance: any) => balance.literAmount > 0)
              .map((balance: any) => (
                <div
                  key={balance?.id}
                  className={'custom-card__block-balance__value'}
                >
                  {balance?.fuel?.title || '-'} /{' '}
                  {Math.trunc(Number(balance?.literAmount) * 100) / 100 || '0'}{' '}
                  л
                </div>
              ))}
        </div>
      </div>
      {/* <div className={'custom-card__custom-name'}>
        Иванов И.И. Камаз 07770022
      </div> */}
    </div>
  )
}
