export const TypeTransaction = [
  { id: 1, name: 'Пополнение' },
  { id: 2, name: 'Списание' },
  { id: 3, name: 'Коррекция' },
  { id: 4, name: 'Возврат' },
]

export const getOperationType = (type: number) => {
  switch (type) {
    case 1:
      return 'Пополнение'
    case 2:
      return 'Списание'
    case 3:
      return 'Коррекция'
    case 4:
      return 'Возврат'
    default:
      return '-'
  }
}
